<template>
  <div>
<!--    <el-tag v-if="showTag" :type="tagType">-->
<!--      {{ label }}-->
<!--    </el-tag>-->
    <div v-if="showTag && tagType==='success'" style="color:#67C23A;">
      {{ label }}
    </div>
    <div v-if="showTag && tagType==='warning'" style="color:#E6A23C;">
      {{ label }}
    </div>
    <div v-if="showTag && tagType==='danger'" style="color:#F56C6C;">
      {{ label }}
    </div>
    <div v-if="showTag && tagType==='info'" style="color:#909399;">
      {{ label }}
    </div>
    <div v-if="showTag && tagType==='primary'" style="color:#409EFF;">
      {{ label }}
    </div>
    <div v-if="!showTag">
      {{ label }}
    </div>
  </div>
</template>

<script>
/**
 * 表格枚举字段展示标签简介：
 *    1.该标签用于将某些枚举字典字段的value值转换为label进行展示，如：state为0，展示为无效
 *    2.使用涉及到几乎所有页面中的state字段
 *    3.具体可以查看上述文件中的简介
 * @author PeterLee
 * @since 2022-02-10
 */
export default {
  name: "EnumDictLabel",
  //设置v-model绑定对象和自动更新响应方法
  model: {
    prop: 'dValue',
  },
  props: {
    //传入展示dValue
    dValue: {
      type: [String, Number],
    },
    //传入字典编码code
    dictCode: {
      type: String,
      default: "state"
    },
    //传入模块名（微服务模块名称）
    moduleName: {
      type: String,
      default: "system"
    }
  },
  data() {
    return {
      //tag类型
      tagType: "primary",
      //展示标签内容
      label: "",
      //是否展示tag样式
      showTag: false,
    };
  },
  watch: {
    dValue: function (newVal) {
      if (newVal !== null) {
        this.getLabel();
      }
    }
  },
  methods: {
    getLabel() {
      //1.特殊tagType设定
      this.specialTagType();
      //2.获取label并展示
      this.$store.dispatch('user/getEnumDictItems',{moduleName:this.moduleName,code:this.dictCode}).then(list => {
        if (list!==undefined && list.length !== 0) {
          const index = list.findIndex(item => item.value === String(this.dValue));
          if(index!==-1){
            const item = list[index];
            this.$nextTick(() => {
              this.label = item.label;
              if (item.style !== undefined && item.style !== null && item.style!=="null") {
                this.showTag = true;
                this.tagType = item.style;
              }
            })
          }
        }
        this.label = this.dValue;
      })

    },
    //特殊tag样式设定
    specialTagType() {
      // //1.如果是状态，统一type所决定样式
      // if(this.dictCode==="state"){
      //   this.dValue===0?this.tagType="warning":this.tagType="success";
      // }
    }
  },
  mounted() {
    this.getLabel()
  }
}
</script>

<style scoped>

</style>