<template>
  <div style="display: inline-block;">
    <el-button type="warning"
               plain
               icon="el-icon-download"
               size="small"
               :loading="exportLoading"
               @click="exportExcel" v-permissionDisabled="permissionCode">
      导出</el-button>
  </div>
</template>

<script>
import {exportExcel} from "@/api/baseApi";

/**
 * 注释：excel导出控件，用于勾选导出excel(与原版差别在于权限码)
 * @author PeterLee
 * @since 2022.02.17
 */
export default {
  name: "ExcelExportPlus",
  props:{
    //模块名
    moduleName: String,
    //表名
    tableName: String,
    //导出文件名
    fileName:String,
    //批量操作选中list
    selectList: {
      type: Array,
      default: []
    },
    // 权限码
    permissionCode:String,
  },
  data(){
    return {
      //导出loading
      exportLoading: false,
    }
  },
  methods:{
    //导出
    exportExcel() {
      let array = this.getSelectList();
      if(array != null){
        this.exportLoading=true;
        exportExcel(this.moduleName,this.tableName,this.fileName,array).then(()=>{
          this.exportLoading = false;
        }).catch(() => {
          this.exportLoading = false;
        })
      }
    },
    //获取选中的数据
    getSelectList() {
      let array = [];
      this.selectList.forEach(row => {
        array.push(row.id);
      })
      if (array.length === 0) {
        this.$message({
          type: 'info',
          message: '请选择要操作的数据'
        });
        return null;
      }
      return array;
    },
  }
}
</script>

<style scoped>

</style>