<!-- 多选关联按钮 代码示范模板 只需要完善相应的位置代码即可 注：表单校验方式请填写change -->
<template>
  <div>
    <!-- 输入框 -->
    <el-input :value="inputValue" @focus="userFocus" :disabled="disabled" :size="size" :placeholder="placeholder"></el-input>
    <el-dialog v-dialogDrag title="" :visible.sync="dialogVisible" center width="976px"
               top="60px" append-to-body>
      <el-row :gutter="20" class="container">
        <!-- 左边主要区 -->
        <el-col :span="15" :xs="24" class=""
                style="background-color: #FFFFFF;height: 100%;border-right: #ECECEC solid 0.01rem; padding: 0.3rem;">
          <div class="left">
            <!-- 顶部搜索区（左边） -->
            <div style="font-size: 0.3rem;font-weight: bold;margin: 0.2rem;display: flex;justify-content: space-between;">
              <el-input v-model="check.name" placeholder="请输入用户名"></el-input>
              <el-input v-model="check.loginName" placeholder="请输入登录名" style="margin-left: 0.2rem;"></el-input>
              <el-button type="primary" @click="handleTableCheck()" style="margin-left: 0.2rem;">查询</el-button>
            </div>
            <!-- 表格区（左边） -->
            <el-table class="table" border ref="multipleTable" :data="tableData" tooltip-effect="dark" highlight-current-row @current-change="handleSingleSelectChange">
              <el-table-column align="center" prop="name" label="用户名">
              </el-table-column>
              <el-table-column align="center" prop="loginName" label="登录名">
              </el-table-column>
              <el-table-column align="center" prop="mobile" label="手机号码" width="155">
              </el-table-column>
            </el-table>
            <!-- 分页区（左边） -->
            <div class="bottom">
              <MyPagination :totalCount="totalCount" :pageSize="pageSize" :currPage="currPage"
                            :pageSizes="pageSizes" :showState="false" :showDelAll="false"
                            @handleSizeChange="handleSizeChange"
                            @handleCurrentChange="handleCurrentChange"></MyPagination>
            </div>
          </div>
        </el-col>
        <!-- 右边主要区 -->
        <el-col :span="9" :xs="24" class="" style="flex-direction: column;border-width: 0;padding-top: 25px;">
          <div class="right">
            <!-- 标题（右边） -->
            <div style="font-size: 0.3rem;font-weight: bold;margin: 0.4rem;">
              已选人员
            </div>
            <!-- 展示内容（右边）-->
            <div class="content" style="overflow: auto;flex:1;">
              <div class="user-item" v-for="(item,index) in selectList" :key="item.id">
                <div style="display: flex;">
                  <div>
                    {{ item.name }}
                  </div>
                  <div style="margin-left: 0.1rem;">
                    ({{ item.loginName }})
                  </div>
                </div>
                <div>
                  <!-- 展示内容关闭按钮X（右边） -->
                  <i class="el-icon-circle-close" style="color: #F56C6C;font-size: 0.3rem;"
                     @click="cancelSelect(index)"></i>
                </div>
              </div>
            </div>
            <!-- 按钮区（右边）-->
            <div class="bottom">
              <el-button type="primary" @click="confirmBack">确认</el-button>
              <el-button @click="clearSelectList">清空</el-button>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import MyPagination from "@/components/My-pagination"

/**
 * 列表弹出框多选选择器
 */
export default {
  name: "SingleSelectorInput",
  components: {
    MyPagination
  },
  // 设置v-model绑定对象和自动更新响应方法
  model:{
    prop:'id',
    event:'change'
  },
  props:{
    id: String,
    disabled:{
      type:Boolean,
      default:false
    },
    size:{
      type:String,
      default: "middle"
    },
    placeholder:{
      type:String,
      default: "请选择人员"
    }
  },
  watch: {
    id:{
      handler(val){
        // 没传入name
        if (val!==undefined&&val!==null) {
          // 数据回显
          this.backShow();
        }
      }
    }
  },
  data() {
    return {
      dialogVisible: false,
      // 输入框输入值
      inputValue:"",
      // 检索内容
      check: {},
      // 表格数据
      tableData: [],
      // 批量操作list(多选)
      selectList: [],
      // 共有数据条数（分页控件）
      totalCount: 0,
      // 当前页数（分页控件）
      currPage: 1,
      // 每页条数（分页控件）
      pageSize: 10,
      // 每页条数选项（分页控件）
      pageSizes: [10, 20, 40, 80],
    }
  },
  created() {
    // 获取关联表信息成功后获取表格信息
    this.handleTableCheck();
  },
  methods: {
    /* * * * * * * * * * * * * * * * *  不用修改的方法 start * * * * * * * * * * * * * * * * */
    // 培训人聚焦
    userFocus() {
      this.dialogVisible = true;
    },
    // 处理单选改变（单选）
    handleSingleSelectChange(raw) {
      if(raw!=null){
        this.selectList.splice(0,1,raw);
      }
    },
    // 右边点击x按钮触发事件
    cancelSelect(index) {
      // 取消高亮
      this.$refs.multipleTable?.setCurrentRow();
      // 清空list
      this.selectList=[];
    },
    // 清空已选
    clearSelectList() {
      this.$refs.multipleTable?.setCurrentRow();
      this.selectList=[];
    },
    // 回显数据勾选状态
    callbackTableCheck() {
      if (this.selectList.length === 0) {
        return;
      }
      // 单人回显
      this.tableData.forEach(data=>{
        if(data.id===this.selectList[0].id){
          this.$refs.multipleTable?.setCurrentRow(data);
        }
      })
    },
    // 当每页展示条数发生变化时，重新请求,页面转回第一页(分页组件)
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.handleTableCheck();
    },
    // 当当前页发生变化时，重新请求(分页组件)
    handleCurrentChange(currentPage) {
      this.currPage = currentPage;
      // 未选中，全文读取
      this.handleTableCheck();
    },
    /* * * * * * * * * * * * * * * * *  不用修改的方法 end * * * * * * * * * * * * * * * * */

    /* * * * * * * * * * * * * * * * * 自定义处理事件 start * * * * * * * * * * * * * * * * */
    // 点击确认按键后
    confirmBack() {
      // 点击确认后，将选中的数据id回绑
      if(this.selectList!=null&&this.selectList.length!==0){
        this.$emit("change",this.selectList[0].id);
      }
      this.dialogVisible=false;
    },
    // 查询表格数据
    handleTableCheck() {
      // 待开发者完善代码...
      // 做表格的分页查询，记得排除state为禁用的，建议后端单独写接口
      let param = {
        currPage: this.currPage,
        limit: this.pageSize,
        ascOrder: false,
        orderField: "",
        queryMap: {
          'name': this.check.name,
          'login_name': this.check.loginName,
        }
      }
      this.$systemApi.getUserPage(param).then(res => {
        if (res.code === 200) {
          this.tableData = res.data.list;
          this.totalCount = res.data.totalCount;
          // 请求成功后回显勾选
          this.callbackTableCheck();
        }
      })
    },
    // 数据回显
    backShow(){
      // 此处由开发者自行填写，目的是做id到label的转换，并呈现到输入框中（编辑时）
      this.$nextTick(() => {
        this.$store.dispatch('system/getUserName', this.id).then(res => {
          this.inputValue=res;
        })
      })
    }
    /* * * * * * * * * * * * * * * * * 自定义处理事件 end * * * * * * * * * * * * * * * * */
  }
}
</script>

<style lang="less" scoped>
.container {
  border: 1px solid #eee;
  display: flex;

}

.left {
  height: 12.74rem;
  display: flex;
  flex-direction: column;

  .table {
    flex: 1;
    overflow: auto;
  }

  .bottom {
    margin-top: 15px;
  }
}

.right {
  flex: 1;
  border: #ECECEC solid 0.01rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  justify-content: space-between;
  height: 12.74rem;

  .content {
    width: 100%;

    .user-item {
      height: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0.1rem 0.2rem;
      border: #ECECEC dashed 0.01rem;
      padding: 0.1rem 0.2rem;
      font-weight: bolder;
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.1rem;
  }
}
</style>