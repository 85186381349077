import state from "./MenuState";
import { addData, getPage, removeById, updateData } from "@/api/baseApi";
import { Message, MessageBox } from 'element-ui'
import {handleLazyTree, handleTree, setDateRange} from "@/utils/common";

/**
 * 菜单权限表数据提供者（vuex数据纳入原则：需要异步请求和与其他组件公用的属性和方法都应纳入其中，每个vue单独的不纳入）
 *
 * @author PeterLee
 * @since 2022-03-10
 */
export default {
    namespaced: true,
    state: state,
    getters: {},
    mutations: {
        /** 设置表单弹窗显示 */
        setDialogVisible(state,commit, visible) {
            state.formInfo.dialogVisible = visible;
        },
        /** 点击提交 */
        clickAdd(state) {
            state.formInfo.isAdd = true;
            state.formInfo.dialogVisible = true;
        },
        /** 点击编辑 */
        clickEdit(state, data) {
            state.formInfo.isAdd = false;
            state.formInfo.dialogVisible = true;
            state.formInfo.editData = data;
        },
        /** 获取parent树 */
        getParentTree(state) {
            const data = state.tree.allTableList.filter(item => item.menuType !== 'F');
            state.tree.parentList = handleTree(data,'id','parentId','childrenList');
        }
    },
    actions: {
        /** 添加提交后执行内容(异步网络请求) */
        submitAdd({commit, dispatch}, data) {
            addData("system", "menu", data).then(res => {
                if (res.code === 200) {
                    //关闭弹出层并刷新树
                    dispatch('handleTableSearch')
                    Message.success("添加数据组件表成功")
                    commit('setDialogVisible', false)
                }
            });
        },
        /** 删除(异步网络请求)  */
        handleDel({dispatch,state}, {row,callback}) {
            //弹出确认框
            MessageBox.confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type:'warning'
            }).then(() => {
                //确认点击删除后，请求后端
                removeById("system", "menu", row.id).then(res => {
                    if (res.code === 200) {
                        //刷新页面数据
                        dispatch('handleTableSearch').then(() => {
                            //重新懒加载父级
                            callback(row.parentId)
                            let index = state.tree.allTableList.findIndex(item => item.id === row.id);
                            state.tree.allTableList.splice(index,1);
                            //顶部显示提示
                            Message.success("删除成功！")
                        })
                    }
                })
            }).catch(() => {
                Message.info("已取消删除")
            });
        },
        /** 处理表格查询(异步网络请求)  */
        handleTableSearch({state}) {
           return new Promise((resolve,reject) => {
                state.queryParams.pageNum = 1;
                let param = state.queryParams;
                for (let key in state.dateRange) {
                    param = setDateRange(param, state.dateRange[key], key);
                }
                getPage("system", "menu", param).then(res => {
                    if (res.code === 200) {
                        state.tree.allTableList = res.data;
                        state.tree.tableData = handleLazyTree(res.data,'0');
                        resolve()
                    }
                    reject()
                })
            })
        },
        /** 表格懒加载 */
        lazyLoad({state},{treeId,resolve}) {
            resolve(handleLazyTree(state.tree.allTableList,treeId))
        },
        /** 获取OptionList(异步网络请求) */
        getOptionList() {
            // getList("manage", "scheduleStrategy").then(res => {
            //     if (res.code === 200) {
            //         options.strategyOptions = res.data;
            //     }
            // })
        },
        /** 编辑提交后执行内容 (异步网络请求)*/
        submitEdit({state,commit, dispatch}, data) {
            return new Promise((resolve,reject) => {
                updateData("system", "menu", data).then(res => {
                    if (res.code === 200) {
                        //关闭弹出层并刷新树
                        Message.success("修改数据组件表信息成功")
                        state.formInfo.dialogVisible = false;
                        resolve()
                    }
                    reject()
                });
            })
        },
    },
    modules: {}
}