<!-- 字典选项选择器组件（用于存在于字典的有限下拉选项框） 事例：详细见下面<script></script>中使用方法 作者：PeterLee 最近修改时间：2021.10.13-->
<template>
  <div>
    <el-select v-model="innerValue" :placeholder="placeholder" style="width: 100%;" :size="size" :clearable="clearable">
      <el-option v-for="item in options" :key="item.id" :label="item.label" :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>

<script>
/**
 * 字典选择器
 * 本控件使用方法：
 *      1.需要配合字典管理模块和前端vuex的store/modules/system.js
 *      2.首先在字典管理模块新增字典，记录其编码
 *      3.然后使用，已有DictionaryItem.vue中搜索栏“状态"和“字典项值"已有事例可供参考（字典项管理（DictionaryItem.vue）中状态选项下拉框,可供参考）
 *      4.注意：字典项下拉框需要将校验规则中的长度校验去除，校验方式改为“change”,否则会报错，即“数据提供者"中的rules
 *  @author PeterLee
 *  @since 2022-02-10
 */
export default {
  name: "DictionarySelect",
  //设置v-model绑定对象和自动更新响应方法
  model: {
    prop: 'dValue',
    event:'change',
  },
  props: {
    dValue: {
      type: [String,Number],
      require:true
    },
    //字典编码
    dictCode: {
      type:String,
      default:""
    },
    placeholder:{
      type:String,
      default: "请选择"
    },
    size:{
      type:String,
      default: "middle"
    },
    clearable:{
      type:Boolean,
      default:false
    },
    echoValue:String,
  },
  watch: {
    dValue:{
      handler(val){
        if(val!==null&&val!==undefined){
          this.innerValue=String(val);
        }else{
          this.innerValue=null;
        }
      }
    },
    innerValue:{
      handler(val){
        this.$emit("change",val)
      }
    }
  },
  data() {
    return {
      innerValue:"",
      //选项
      options:[],
    };
  },
  methods:{
    getOptions(){
        this.$nextTick(()=>{
          this.$store.dispatch('system/getDictionaryItems', this.dictCode).then(res => {
            this.options = res;
          })
        })
    },
  },
  mounted() {
    this.getOptions()
  }
}
</script>

<style scoped>

</style>