import router from './router'
import store from './store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

NProgress.configure({ showSpinner: false })

const whiteList = ['/userLogin','/index','/register','/resetPassword']

router.beforeEach((to, from, next) => {
    NProgress.start()
    const token = `${store.state.user.UserToken}`
    if (token&&token!=='null') {
        // to.meta.title && store.dispatch('settings/setTitle', to.meta.title)
        /* has token*/
        if (to.path === '/userLogin') {
            next({ path: '/' })
            NProgress.done()
        } else {
            if (store.getters["user/getPermission"].length === 0) {
                // 判断当前用户是否已拉取完user_info信息
                store.dispatch('user/getUserInfo').then(() => {
                    store.dispatch('GenerateRoutes').then(accessRoutes => {
                        // 根据roles权限生成可访问的路由表
                        for (const index in accessRoutes){
                            router.addRoute(accessRoutes[index]); // 动态添加可访问路由表
                        }
                        next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
                    })
                }).catch(() => {
                    store.dispatch('user/logout').then(()=>{});
                })
            } else {
               next()
            }
        }
    } else {
        // 没有token
        if (whiteList.indexOf(to.path) !== -1) {
            // 在免登录白名单，直接进入
            next()
        } else {
            next(`/userLogin?redirect=${to.fullPath}`) // 否则全部重定向到登录页
            NProgress.done()
        }
    }
})

router.afterEach(() => {
    NProgress.done()
})
