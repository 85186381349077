<template>
  <el-dropdown class="dropdown" type="info" size="mini" placement="bottom" @command="handleCommand($event,row)">
    <el-button type="info" size="mini" :disabled="disabled">
      更多<i class="el-icon-arrow-down el-icon--right"></i>
    </el-button>
    <el-dropdown-menu class="dropdownMenu" slot="dropdown" >
      <el-dropdown-item class="dropdownItem" v-for="item in buttonProps" :key="item.command" :command="item.command" v-permissionDisabled="item.permissionCode">{{item.label}}</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
/**
 * 表格操作列=>'更多'按钮
 * 注释：‘更多’按钮在窗口缩小时会显示在左下角，大概是动态计算尺寸的问题，暂时未找到较好的解决办法，建议将窗口做默认放大处理
 * 参考：https://blog.csdn.net/weixin_53594144/article/details/125517898
 * @author PeterLee
 * @since 2022-03-01
 */
export default {
  name: "TableMoreButton",
  props:{
    //行数据
    row:Object,
    //按钮props(key不能重复)
    buttonProps:{
      type:Array,
      default:()=>{
        return [
          // {
          //   command: 'log',
          //   label: '日志(范例)',
          //   permissionCode: '*:*:*'
          // }
        ]
      }
    }
  },
  data(){
    return {
       disabled: false,
    }
  },
  created() {
    this.handleMoreButtonDisabled();
  },
  methods:{
    //1.处理多按钮点击事件
    handleCommand(command,row){
       this.$emit("handleCommand",command,row)
    },
    //2.处理更多按钮disabled
    handleMoreButtonDisabled(){
      this.disabled = this.buttonProps === null || this.buttonProps === undefined || this.buttonProps.length === 0;
    }
  }
}
</script>

<style scoped>
.dropdown {
  margin-left: 10px;
  margin-right: 10px;
  display: inline-block;
}
.dropdownMenu {
  /*width: 100px;*/
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}
.dropdownItem{
  margin-left: 0;
  margin-right: 0;
}
</style>