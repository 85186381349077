const drag = {
    // 指令的定义
    bind: function(el) {
        let oDiv = el;  // 获取当前元素
        oDiv.onmousedown = (e) => {
            console.log('onmousedown')
            // 算出鼠标相对元素的位置
            let disX = e.clientX - oDiv.offsetLeft;
            let disY = e.clientY - oDiv.offsetTop;

            document.onmousemove = (e) => {
                // 用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
                let left = e.clientX - disX;
                let top = e.clientY - disY;

                oDiv.style.left = left + 'px';
                oDiv.style.top = top + 'px';

            };

            document.onmouseup = () => {
                document.onmousemove = null;
                document.onmouseup = null;
            }
        }
    }
}
export default drag