import widget from "@/views/data/widget/WidgetDataProvider";
import source from "@/views/data/source/sourceDataProvider";
import sourceType from "@/views/data/sourceType/sourceTypeDataProvider";
import dataBase from "@/views/data/dataBase/dataBaseDataProvider";
import table from "@/views/data/table/tableDataProvider";
import field from "@/views/data/field/fieldDataProvider";
export default {
    namespaced: true,
    modules: {
        widget,
        source,
        sourceType,
        dataBase,
        table,
        field,
    },
}