import state from "./UserState";
import {addData, getList, getPage, removeById, updateData} from "@/api/baseApi";
import { Message, MessageBox } from 'element-ui'
import {handleTree, setDateRange} from "@/utils/common";
import {resetPassword, clickAuthorizationLogin, getRoleIdsByUserId, userBatchRole} from "@/api/systemApi";

/**
 * 系统用户表数据提供者（vuex数据纳入原则：需要异步请求和与其他组件公用的属性和方法都应纳入其中，每个vue单独的不纳入）
 *
 * @author PeterLee
 * @since 2022-06-22
 */
export default {
    namespaced: true,
    state: state,
    getters: {},
    mutations: {
        /** 设置表单弹窗显示 */
        setDialogVisible(state, visible) {
            state.formInfo.dialogVisible = visible;
        },
        /** 点击提交 */
        clickAdd(state) {
            state.formInfo.isAdd = true;
            state.formInfo.dialogVisible = true;
        },
        /** 点击编辑 */
        clickEdit(state, data) {
            state.formInfo.isAdd = false;
            state.formInfo.dialogVisible = true;
            state.formInfo.editData = data;
        },
    },
    actions: {
        /** 添加提交后执行内容(异步网络请求) */
        submitAdd({commit, dispatch}, data) {
            addData("system", "user", data).then(res => {
                if (res.code === 200) {
                    //关闭弹出层并刷新树
                    dispatch('handleTableSearch')
                    Message.success("添加数据组件表成功")
                    commit('setDialogVisible', false)
                }
            });
        },
        /** 删除(异步网络请求)  */
        handleDel({dispatch}, data) {
            //弹出确认框
            MessageBox.confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type:'warning'
            }).then(() => {
                //确认点击删除后，请求后端
                removeById("system", "user", data.id).then(res => {
                    if (res.code === 200) {
                        //刷新页面数据
                        dispatch('handleTableSearch')
                        //顶部显示提示
                        Message.success("删除成功！")
                    }
                })
            }).catch(() => {
                Message.info("已取消删除")
            });
        },
        /** 获取关联角色ids */
        getRelationRoleIds({state},{callback,row}){
            getRoleIdsByUserId(row.id).then(res => {
                if (res.code === 200) {
                    callback(res.data);
                }
            })
        },
        /** 关联角色 */
        relationRoles({state},{checkedIds,callback,row}){
            const param = {
                userId: row.id,
                roleIds: checkedIds
            }
            userBatchRole(param).then(res => {
                if (res.code === 200) {
                    callback()
                }
            })
        },
        /** 处理重置密码(异步网络请求)  */
        handleResetPassword({state},data) {
            resetPassword(data.id).then(res => {
                if (res.code === 200) {
                    Message.success("密码重置成功")
                }
            })
        },
        /** 处理登录授权(异步网络请求)  */
        clickAuthorizationLogin({state,dispatch},data) {
            clickAuthorizationLogin(data.id).then(res => {
                if (res.code === 200) {
                    Message.success("登录授权成功")
                    dispatch('handleTableSearch')
                }
            })
        },
        /** 处理表格查询(异步网络请求)  */
        handleTableSearch({state}) {
            state.queryParams.pageNum = 1;
            let param = state.queryParams;
            for (let key in state.dateRange) {
                param = setDateRange(param, state.dateRange[key], key);
            }
            getPage("system", "user", param).then(res => {
                if (res.code === 200) {
                    state.tableData = res.data.list;
                    state.totalCount = res.data.totalCount;
                }
            })
        },
        /** 获取OptionList(异步网络请求) */
        getOptionList({state}) {
            getList("system", "organization").then(res => {
                if (res.code === 200) {
                    let data = handleTree(res.data,'id','parentId','childrenList');
                    state.options.organizationTreeOptions = data;
                    state.tree.treeData =  data;
                }
            })
            getList("system", "position").then(res => {
                if (res.code === 200) {
                    state.options.positionOptions = res.data;
                }
            })
        },
        /** 编辑提交后执行内容 (异步网络请求)*/
        submitEdit({commit, dispatch}, data) {
            updateData("system", "user", data).then(res => {
                if (res.code === 200) {
                    //关闭弹出层并刷新树
                    dispatch('handleTableSearch')
                    Message.success("修改数据组件表信息成功")
                    commit('setDialogVisible', false)
                }
            });
        },
        /* * * * * * * * * * * * 树区 start * * * * * * * * * * * */
        /** 处理树节点点击事件 */
        handleNodeClick({state,dispatch},data) {
            if (data.level !== 0) {
                //节点赋值到params（treeId替换为绑定的树id字段名）
                state.queryParams.param.organizationId = data.id;
                //查询page
                dispatch('handleTableSearch')
            }
        },
        /* * * * * * * * * * * * 树区 end * * * * * * * * * * * */
    },
    modules: {}
}