import user from "@/store/modules/user";

/**
 * 自定义权限码校验指令
 * @author PeterLee
 * @date 2021-10-21
 * @type {{inserted: permission.inserted}}
 */
const permission = {
	inserted:function (el, binding, vnode) {
		const { value } = binding
		// 自定义指令的执行函数，取到登录缓存的按钮权限数组，赋值给全局对象userMsg
		const userInfo = user.state.UserInfo
		// 没有获取到权限码
		if ( userInfo.permissions === null ) {
			el.style.display = 'none';
			return;
		}
		// 是管理员，所有都可见
		if ( userInfo.permissions.indexOf("*:*:*") === 0 ) {
			return;
		}
		if ( userInfo.permissions.indexOf(value) < 0 ) {
			el.style.display = 'none';
		}
	}
}

export default permission
