<template>
  <div>
    <el-input placeholder="请输入内容" v-model="innerValue" class="input-with-select" :disabled="disabled">
      <el-button slot="append" icon="el-icon-folder" type="success" @click="handleOpenMenu"></el-button>
    </el-input>
  </div>
</template>

<script>
/**
 * 文件夹路径选择器
 * @author PeterLee
 * @since 2023-01-31
 */
export default {
  name: "DirectorySelect",
  //设置v-model绑定对象和自动更新响应方法
  model: {
    prop: 'path',
    event: 'change',
  },
  props: {
    path: String,
    disabled: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    innerValue: {
      get() {
        return this.path;
      },
      set(val) {
        this.$emit('change', val);
      }
    }
  },
  methods: {
    handleOpenMenu () {
      const path = window.ipcRenderer.sendSync('open-directory-dialog', 'openDirectory');
      this.$emit("change", path)
    }
  }
}
</script>

<style scoped>

</style>