<!-- 拥有选项的id转特定fieldName标签（用于将list下拉选项value转为其对应的特定字段label）参考：<script></script>中简介可供参考 作者：PeterLee 最近修改时间：2021.10.13-->
<template>
  <div>
    {{ fieldValue }}
  </div>
</template>

<script>
/**
 * @author PeterLee
 * @date 2021-10-13
 * 注：在排班管理中有使用
 */
export default {
  name: "OptionNameLabel",
  //设置v-model绑定对象和自动更新响应方法
  model: {
    prop: 'id',
  },
  props: {
    id: String,
    options: Array,
    fieldName: {
      type:String,
      default: 'name'
    },
  },
  data() {
    return {
      //字段名显示
      fieldValue: ""
    };
  },
  watch: {
    options: function (newVal) {
      if (newVal!==undefined&&newVal!==null) {
        this.getFieldName();
      }
    },
    id: function (newVal) {
       if(newVal!==undefined&&newVal!==null){
         this.getFieldName();
       }
    },
    echoValue:{
      handler(val){
        this.$emit('change',val);
      }
    },
  },
  methods: {
    //获取字段名
    getFieldName() {
      if(this.id !== null){
        this.$nextTick(()=>{
          this.fieldValue = this.findField(this.options, this.id, this.fieldName);
        })
      }
    },
    findField(items, idValue, filedName) {
      if (items !== undefined && items !== null) {
        let item = items.find((item) => item.id === idValue);
        if (item === null || item === undefined) {
           return;
        }
        let result = item[filedName];
        if (result !== undefined && result !== null) {
          return result;
        }
      }
      return idValue;
    }
  },
  mounted() {
    this.getFieldName();
  }
}
</script>

<style scoped>

</style>
