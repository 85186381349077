<script>
/**
 * 标签输入框（返回结果用字符串数组接收，后端再转为字符串逗号隔开，或者单独建表存放）
 * @author PeterLee
 * @since 2023-02-13
 */
export default {
  name: 'ElTagInput',
  //设置v-model绑定对象和自动更新响应方法
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    // 组件绑定值
    value: {
      type: Array,
      default: () => []
    },
    // 未知字段
    addTagOnKeys: {
      type: Array,
      default: () => [13, 188, 9]
    },
    // 只读（没有close按钮，没有输入框）
    readOnly: {
      type: Boolean,
      default: false
    },
    // 输入框初始大小
    size: {
      type: String,
      default: "middle"
    },
    // 标签最大字数
    maxWordCount: {
      type: Number,
      default: 19
    },
    // 填充字段
    placeholder: String,
    // 禁用动画
    disableTransitions: {
      type: Boolean,
      default: true
    },
    // close可点击否
    removeAble: {
      type: Boolean,
      default: true
    },
    // 标签类型
    tagType: {
      type: String,
      default: "primary"
    }
  },
  data() {
    return {
      // 输入框正在新增的tag值
      newTag: '',
      // 已经存在的tags
      innerTags: [...this.value],
      // 组件整体聚焦标识
      isFocus: false
    }
  },
  watch: {
    // 监听传入的value，变化后将value赋值给innerTags
    value() {
      this.innerTags = [...this.value]
    }
  },
  methods: {
    // 组件聚焦触发事件
    focusTagInput() {
      this.isFocus = true
      this.$emit('focus')
      if (this.readOnly || !this.$el.querySelector('.tag-input')) {
        // 无 （祝愿大家身体健康，万事如意）
      } else {
        this.$el.querySelector('.tag-input').focus()
      }
    },
    // 输入框input事件
    inputTag(ev) {
      this.newTag = ev.target.value
    },
    // 输入框focus事件
    addFocus(e) {
      this.$emit('focus', e)
    },
    // 输入框keydown和blur事件
    addNew(e) {
      this.isFocus = false
      this.$emit('blur')
      if (e && (!this.addTagOnKeys.includes(e.keyCode)) && (e.type !== 'blur')) {
        return
      }
      if (e) {
        e.stopPropagation()
        e.preventDefault()
      }
      let addSuccess = false
      if (this.newTag.includes(',')) {
        this.newTag.split(',').forEach(item => {
          if (this.addTag(item.trim())) {
            addSuccess = true
          }
        })
      } else {
        if (this.addTag(this.newTag.trim())) {
          addSuccess = true
        }
      }
      if (addSuccess) {
        this.tagChange()
        this.newTag = ''
      }
    },
    // 添加tag事件
    addTag(tag) {
      tag = tag.trim()
      if (tag && !this.innerTags.includes(tag)) {
        this.innerTags.push(tag)
        return true
      }
      return false
    },
    // 标签关闭事件
    tagClose(index) {
      if (this.removeAble) {
        this.innerTags.splice(index, 1)
        this.tagChange()
        this.$el.querySelector('.tag-input').focus()
        this.isFocus = true
      }
      this.$emit('tagClose')
    },
    // 移除最后一个标签（点击删除键事件） @keydown.delete.stop
    removeLastTag() {
      if (this.newTag) {
        return
      }
      this.innerTags.pop()
      this.tagChange()
    },
    // tag改变事件（向父组件抛出input监听事件）
    tagChange() {
      this.$emit('input', this.innerTags)
    },
    // 获取输入字符串长度
    getInputSize(str) {
      if (!str) {
        return 0
      }
      // 下标计数器
      let index = 0
      // 返回的展示截至下标，为0表示默认值，即不用触发缩写操作
      let showIndex = 0
      // 英文数字和汉字比例为1：1.7
      str.split('').reduce((prev, curr) => {
        if (prev > this.maxWordCount && showIndex === 0) {
          showIndex = index - 1
        }
        index++
        // 英文字母和数字等算一个字符；这个暂时还不完善；
        if (/[a-z]|[0-9]|[,;.!@#-+/\\$%^*()<>?:"'{}~]/i.test(curr)) {
          return prev + 1
        }
        // 其他的算是2个字符
        return prev + 1.7
      }, 0)
      // 向上取整，防止出现半个字的情况
      return showIndex
    },
    // 获取显示文本(超出了限制范围之后，需要缩减)
    getDisplayText(str) {
      if (str) {
        let showIndex = this.getInputSize(str)
        // 可展示内容下标不为0即表示需要截取缩略展示
        if (showIndex !== 0) {
          return str.substring(0, showIndex) + '...'
        }
      }
      return str
    }
  }
}
</script>

<template>
  <div
      class="el-input-tag input-tag-wrapper"
      :class="[size ? 'el-input-tag--' + size : '',isFocus ? 'el-input-tag--focus': '']"
      @click="focusTagInput">
    <!--超限提示（超出字数顶部提示）-->
    <el-tooltip v-for="(tag, idx) in innerTags" :disabled="tag?getInputSize(tag) === 0 : true" class="item"
                effect="light" :content="tag" placement="top">
      <!-- 标签显示 -->
      <el-tag
          v-bind="$attrs"
          :key="tag"
          :size="size"
          :closable="!readOnly"
          :type="tagType"
          :disable-transitions="disableTransitions"
          @close="tagClose(idx)">
        {{ getDisplayText(tag) }}
      </el-tag>
    </el-tooltip>
    <!-- 标签后输入框 -->
    <input
        v-if="!readOnly"
        class="tag-input"
        :placeholder="placeholder"
        @input="inputTag"
        :value="newTag"
        @keydown.delete.stop="removeLastTag"
        @keydown="addNew"
        @focus="addFocus"
        @blur="addNew"/>
  </div>
</template>

<style scoped>
.el-form-item.is-error .el-input-tag {
  border-color: #f56c6c;
}

.input-tag-wrapper {
  position: relative;
  font-size: 14px;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  outline: none;
  padding: 0 10px 0 5px;
  transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
  width: 100%;
}

.el-tag {
  margin-right: 4px;
}

.tag-input {
  background: transparent;
  border: 0;
  font-size: inherit;
  outline: none;
  padding-left: 0;
  width: 100px;
}

.el-input-tag {
  min-height: 42px;
}

.el-input-tag--mini {
  min-height: 28px;
  line-height: 28px;
  font-size: 12px;
}

.el-input-tag--small {
  min-height: 32px;
  line-height: 32px;
}

.el-input-tag--medium {
  min-height: 36px;
  line-height: 36px;
}

.el-input-tag--focus {
  border: 1px solid #3a8ee6;
}
</style>