import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from "@/store/index.js"

import echarts from "echarts"
import { getUrlData } from "@/utils/urlParam.js"
import * as systemApi from './api/systemApi.js'

import { setDateRange } from "@/utils/common";

import ElementUI from "element-ui"
import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/common.css'

import VueParticles from 'vue-particles'
import "@/utils/auto-size.js"
import "./permission"
import Directives from '@/directive/index'

import userNameLabel from "@/components/label/UserNameLabel"
import singleUserInput from "@/components/widget/SingleUserInput"
import userSelector from "@/components/widget/UserSelector"
import dictionarySelect from "@/components/select/DictionarySelect"
import dictionaryLabel from "@/components/label/DictionaryLabel"
import enumDictSelect from "@/components/select/EnumDictSelect"
import enumDictLabel from "@/components/label/EnumDictLabel"
import optionNameLabel from "@/components/label/OptionNameLabel"
import treeOptionNameLabel from "@/components/label/TreeOptionNameLabel"
import rightToolBar from "@/components/widget/RightToolBar"
import rightToolBarPlus from "@/components/widget/RightToolBarPlus";
import pagination from "@/components/widget/Pagination"
import paginationPlus from "@/components/widget/PaginationPlus";
import selectTree from '@/components/select/TreeSelect'
import excelImport from "@/components/button/ExcelImport.vue";
import excelImportPlus from "@/components/button/ExcelImportPlus.vue";
import excelExport from "@/components/button/ExcelExport.vue";
import excelExportPlus from "@/components/button/ExcelExportPlus.vue";
import excelExportAll from "@/components/button/ExcelExportAll.vue";
import baseFormPlus from "@/components/widget/BaseFormPlus";
import afTableColumn from "@/components/label/AFTableColumn/AFTableColumn"
import tableMoreButton from "@/components/button/TableMoreButton.vue";
import batchSelectorButton from "@/components/button/BatchSelectorButton.vue";
import singleSelectorInput from "@/components/widget/SingleSelectorInput";
import optionsSelect from "@/components/select/OptionsSelect";
import directorySelect from "@/components/select/DirectorySelect";
import dynamicTemplate from "@/components/widget/DynamicTemplate";
import labelTip from "@/components/widget/LabelTip";
import ElTagInput from "@/components/input/ElTagInput";
import ImageUploadSelect from "@/components/select/ImageUploadSelect";
import FileUploadSelect from "@/components/select/FileUploadSelect";

/**
 * 时间格式化
 * @param format
 * @returns {*}
 */
Date.prototype.format = function formatter (format) {
  var o = {
    "M+": this.getMonth() + 1, //month
    "d+": this.getDate(), //day
    "h+": this.getHours(), //hour
    "m+": this.getMinutes(), //minute
    "s+": this.getSeconds(), //second
    "q+": Math.floor((this.getMonth() + 3) / 3), //quarter
    S: this.getMilliseconds() //millisecond
  };
  if (/(y+)/.test(format))
    format = format.replace(
        RegExp.$1,
        (this.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (var k in o) {
    if (new RegExp("(" + k + ")").test(format))
      format = format.replace(
          RegExp.$1,
          RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  }
  return format;
};

/**
 * 配置全局变量
 */
Vue.prototype.$echarts = echarts;
Vue.prototype.$getUrlData = getUrlData;
Vue.prototype.$systemApi = systemApi;

/**
 * 配置全局方法
 */
Vue.prototype.$setDataRange = setDateRange;

/**
 * 全局配置Dialog点击遮罩不关闭
 */
ElementUI.Dialog.props.closeOnClickModal.default = false;

/**
 * 阻止 vue 在启动时生成生产提示(开发环境可以打开，生成环境需要关闭)
 */
Vue.config.productionTip = false;

/**
 * 引入ElementUi
 */
Vue.use(ElementUI)

/**
 * 引入动画背景
 */
Vue.use(VueParticles)

/**
 * 引入自定义指令鉴权（有使用）
 */
Vue.use(Directives)

/**
 * 引入自定义组件
 */
Vue.component("base-form-plus",baseFormPlus)
Vue.component("user-name-label",userNameLabel)
Vue.component("single-user-input",singleUserInput)
Vue.component("dictionary-select",dictionarySelect)
Vue.component("dictionary-label",dictionaryLabel)
Vue.component("enum-dict-select",enumDictSelect)
Vue.component("enum-dict-label",enumDictLabel)
Vue.component("user-selector",userSelector)
Vue.component("option-name-label",optionNameLabel)
Vue.component("tree-option-name-label",treeOptionNameLabel)
Vue.component("right-toolbar",rightToolBar)
Vue.component("right-toolbar-plus",rightToolBarPlus)
Vue.component("pagination",pagination)
Vue.component("pagination-plus",paginationPlus)
Vue.component("select-tree",selectTree)
Vue.component("excel-import",excelImport)
Vue.component("excel-import-plus",excelImportPlus)
Vue.component("excel-export",excelExport)
Vue.component("excel-export-plus",excelExportPlus)
Vue.component("excel-export-all",excelExportAll)
Vue.component("af-table-column",afTableColumn)
Vue.component("table-more-button",tableMoreButton)
Vue.component("batch-selector-button",batchSelectorButton)
Vue.component("single-selector-input",singleSelectorInput)
Vue.component("options-select", optionsSelect)
Vue.component("directory-select",directorySelect)
Vue.component("dynamic-template",dynamicTemplate)
Vue.component("label-tip",labelTip)
Vue.component("el-tag-input",ElTagInput)
Vue.component("image-upload-select",ImageUploadSelect)
Vue.component("file-upload-select",FileUploadSelect)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
