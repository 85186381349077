import {validateNumInteger} from "@/utils/validateUtil";

/**
 * 系统组织表	状态管理（vuex的state可以单独出来，便于管理，同样mutations和actions都可以提取出来）
 *
 * @author PeterLee
 * @since 2022-06-23
 */
const state = {
   //表单校验规则
   rules: {
      //组织名
      name:[{
         required: true,
         message: '请输入组织名',
         trigger: 'blur'
      },
        {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
        }
     ],
      //组织代码
      code:[{
         required: true,
         message: '请输入组织代码',
         trigger: 'blur'
      },
        {
            min: 1,
            max: 50,
            message: '长度在 1 到 50 个字符',
            trigger: 'blur'
        }
     ],
      //父组织id
      parentId:[{
         required: true,
         message: '请选择父组织',
         trigger: 'change'
      }],
      //层级
      hierarchy:[{
         validator:validateNumInteger,
         required: true,
         message: '请输入层级(正整数)',
         trigger: 'blur'
      }],
      //排序号
      sort:[{
         validator:validateNumInteger,
         required: true,
         message: '请输入排序号(正整数)',
         trigger: 'blur'
      }],
      //状态 0：无效 1：有效
      state:[{
         required: true,
         message: '请选择状态',
         trigger: 'change'
      }],
   },
   //列信息
   columnInfo: {
       //组织名
       name:{
            visible:true,
            label:"组织名"
       },
       //组织代码
       code:{
            visible:true,
            label:"组织代码"
       },
       //父组织id
       parentId:{
            visible:true,
            label:"父组织"
       },
       //祖级列表
       ancestors:{
            visible:true,
            label:"祖级列表"
       },
       //层级
       hierarchy:{
            visible:true,
            label:"层级"
       },
       //排序号
       sort:{
            visible:true,
            label:"排序号"
       },
       //状态 0：无效 1：有效
       state:{
            visible:true,
            label:"状态"
       },
   },
   //表格数据（查询结果）
   tableData: [],
   //共有数据条数（分页控件）
   totalCount: 0,
   //选项列表（列表下拉框选择）
   options: {
       organizationOptions:undefined,
   },
   //查询参数
   queryParams: {
      //当前页数（分页控件）
      currPage: 1,
      //每页条数（分页控件）
      limit: 10,
      //是否升序
      ascOrder: false,
      //排序字段
      orderField: "",
      //此处为可能需要修改部分，请不要忽略
      param: {
         //组织名
         name: undefined,
         //组织代码
         code: undefined,
         //父组织id
         parentId: undefined,
         //祖级列表
         ancestors: undefined,
         //层级
         hierarchy: undefined,
         //排序号
         sort: undefined,
         //状态 0：无效 1：有效
         state: undefined,
      }
   },
   //时间范围
   dateRange: {

   },
   //表单组件信息
   formInfo: {
      //表单组件弹出框显示控制
      dialogVisible: false,
      //是否增加操作（非增加操作会隐藏部分操作）
      isAdd: false,
      //编辑回显数据
      editData: {},
   },
   //更多按钮信息
   moreButtonProps:[
      //{command:'test',label:'示例按钮',permissionCode:'itil:event'}
   ]
}
export default state