<template>
  <div>
    <el-select v-model="innerValue" :placeholder="placeholder" :size="size" :clearable="clearable" :disabled="disabled"
               @change="selectTypeChange(innerValue)">
      <el-option
          v-for="item in options"
          :key="item[props.key]"
          :label="item[props.label]"
          :value="item[props.value]">
      </el-option>
    </el-select>
  </div>
</template>

<script>
/**
 * 列表选择器
 * @author PeterLee
 * @since 2022-01-14
 */
export default {
  name: "OptionsSelect",
  model: {
    prop: 'dValue',
    event: 'change',
  },
  props: {
    dValue: {
      type: [String, Number],
      require: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: "请选择"
    },
    size: {
      type: String,
      default: "middle"
    },
    clearable: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: function () {
        return []
      }
    },
    props: {
      type: Object,
      default: function () {
        return {
          "key": "id",
          "label": "name",
          "value": "id"
        }
      }
    }
  },
  watch: {
    dValue: {
      handler(val) {
        if (val !== null && val !== undefined) {
          this.innerValue = String(val);
        } else {
          this.innerValue = null;
        }
      }
    },
    innerValue: {
      handler(val) {
        this.$emit("change", val)
      }
    },
    options: {
      deep: true, // 深度监听，因为options是一个数组
      handler(newVal) {
        // 当options变化时，检查innerValue是否仍然在新options的有效值中
        const optionValues = newVal.map(option => option[this.props.value]);
        if (!optionValues.includes(this.innerValue)) {
          // 如果innerValue不在新options中，则重置它
          // 你可以根据需要自定义重置逻辑，比如设置为null，或者设置为新options的第一个值
          this.innerValue = this.dValue; // 或者 this.innerValue = newVal.length > 0 ? String(newVal[0][this.props.value]) : null;
          console.log(this.innerValue)
          // 触发change事件，以通知父组件dValue可能已经变化
          this.$emit("change", this.innerValue);
        }
      }
    }
  },
  data() {
    return {
      innerValue: "",
    };
  },
  methods: {
    selectTypeChange(val) {
      this.$emit("change", val)
    }
  }
}
</script>

<style scoped>

</style>