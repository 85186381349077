/**
 * 数据源类型表状态管理（vuex的state可以单独出来，便于管理，同样mutations和actions都可以提取出来）
 *
 * @author PeterLee
 * @since 2022-07-18
 */
const state = {
    //表单校验规则
    rules: {
        //类型名
        name: [{
            required: true,
            message: '请输入类型名',
            trigger: 'blur'
        },
            {
                min: 1,
                max: 20,
                message: '长度在 1 到 20 个字符',
                trigger: 'blur'
            }
        ],
        //名称简写
        shortName: [{
            required: true,
            message: '请输入名称简写',
            trigger: 'blur'
        },
            {
                min: 1,
                max: 20,
                message: '长度在 1 到 20 个字符',
                trigger: 'blur'
            }
        ],
        //驱动名称
        driverName: [{
            required: true,
            message: '请输入驱动名称',
            trigger: 'blur'
        },
            {
                min: 1,
                max: 60,
                message: '长度在 1 到 60 个字符',
                trigger: 'blur'
            }
        ],
        //路径前缀
        prefix: [{
            required: true,
            message: '请输入路径前缀',
            trigger: 'blur'
        },
            {
                min: 1,
                max: 100,
                message: '长度在 1 到 100 个字符',
                trigger: 'blur'
            }
        ],
        //路径后缀
        suffix: [{
            required: true,
            message: '请输入路径后缀',
            trigger: 'blur'
        },
            {
                min: 1,
                max: 200,
                message: '长度在 1 到 200 个字符',
                trigger: 'blur'
            }
        ],
        //排序
        sort: [{
            required: true,
            message: '请输入排序',
            trigger: 'blur',
        },
            {
              type: 'number',
              message: '排序必须为数字值'
            }
        ],
        //状态
        state: [{
            required: true,
            message: '请输入状态',
            trigger: 'change'
        }],
    },
    //列信息
    columnInfo: {
        //类型名
        name: {
            visible: true,
            label: "类型名"
        },
        //名称简写
        shortName: {
            visible: true,
            label: "名称简写"
        },
        //驱动名称
        driverName: {
            visible: true,
            label: "驱动名称"
        },
        //路径前缀
        prefix: {
            visible: true,
            label: "路径前缀"
        },
        //路径后缀
        suffix: {
            visible: true,
            label: "路径后缀"
        },
        //排序
        sort: {
            visible: true,
            label: "排序"
        },
        //状态
        state: {
            visible: true,
            label: "状态"
        },
    },
    //表格数据（查询结果）
    tableData: [],
    //共有数据条数（分页控件）
    totalCount: 0,
    //选项列表（列表下拉框选择）
    options: {},
    //查询参数
    queryParams: {
        //当前页数（分页控件）
        currPage: 1,
        //每页条数（分页控件）
        limit: 10,
        //是否升序
        ascOrder: false,
        //排序字段
        orderField: "",
        //此处为可能需要修改部分，请不要忽略
        param: {
            //类型名
            name: undefined,
            //名称简写
            shortName: undefined,
            //路径前缀
            prefix: undefined,
            //路径后缀
            suffix: undefined,
            //排序
            sort: undefined,
            //状态
            state: undefined,
        }
    },
    //时间范围
    dateRange: {},
    //表单组件信息
    formInfo: {
        //表单组件弹出框显示控制
        dialogVisible: false,
        //是否增加操作（非增加操作会隐藏部分操作）
        isAdd: false,
        //编辑回显数据
        editData: {},
    },
    //更多按钮信息
    moreButtonProps: [
        //{command:'test',label:'示例按钮',permissionCode:'itil:event'}
    ]
}
export default state