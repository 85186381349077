<!--底部批量删除、禁用、启用组件 事例：UserManage有用到 作者：PeterLee 最近修改时间：2021.9.4-->
<template>
  <div class="pagi-container">
      <div>
          <el-button type="danger" size="small"  plain @click="handleDelAll" v-if="showDelAll">批量删除</el-button>
          <el-button type="primary" size="small" plain @click="handleState(false)" v-if="showState">启用</el-button>
          <el-button type="warning" size="small" plain @click="handleState(true)" v-if="showState">禁用</el-button>
      </div>
       <el-pagination background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currPage"
      :page-sizes="pageSizes"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalCount">
    </el-pagination>
  </div>
</template>

<script>
export default {
	//父组件传入参数
	props:{
		//显示批量删除按钮
		showDelAll:{
			type:Boolean,
			default: true
		},
		//显示启用按钮
		showState:{
			type:Boolean,
			default: true
		},
		//每页显示多少条选项数组
		pageSizes:{
			type: Array,
			default:() =>[10, 20, 30, 40]
		},
		//初始当前页码
		currPage:{
			type: Number,
			default: 1
		},
		//初始的显示数目
		pageSize:{
			type:Number,
			default: 10
		},
		//初始值一共多少条数据
		totalCount:{
			type: Number,
			default: 0
		}
	},
    data(){
        return{
			
        }
    },
    methods:{
		//当每页展示条数发生变化时，重新请求,页面转回第一页
         handleSizeChange(pageSize) {
			 //传回每页条数
			this.$emit('handleSizeChange',pageSize);
        },
		//当当前页发生变化时，重新请求
        handleCurrentChange(currentPage) {
			//传回页码
			this.$emit('handleCurrentChange',currentPage);
        },
		//点击批量删除时
		handleDelAll(){
			this.$emit('handleDelAll');
		},
		//点击启用禁用时,disabled==true为禁用
		handleState(disabled){
			this.$emit('handleState',disabled);
		}
    }
}
</script>

<style lang="less" scoped>
.pagi-container{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>