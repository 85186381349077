<!-- 导入按钮控件 事例：详细见下面<script></script>中使用注释 作者：PeterLee 最近修改时间：2021.10.14-->
<template>
  <div class="excelImport">
    <el-button type="primary" plain @click="dialogVisible = true">导入</el-button>
    <!-- 导入弹窗 -->
    <el-dialog :visible.sync="dialogVisible" width="560px" :title="title + '导入'"
               :close-on-click-modal="false" @close="fileList = []" class="import-dialog">
      <div class="container">
        <el-form ref="ruleForm" label-width="100px">
<!--          <p class="title">{{title + '导入'}}</p>-->
          <div class="info">
            <div v-loading="loading" element-loading-text="正在导入">
              <div style="color: #2D2D2D;margin-bottom: 20px;">导入Excel文件</div>
              <el-upload class="upload-demo"
                         ref="upload"
                         name="file"
                         :on-change="changeUpload"
                         :file-list="fileList"
                         :multiple="false"
                         :auto-upload="false"
                         :limit="2"
                         :http-request="uploadExcel"
              >
                <el-button slot="trigger" type="success">选取文件</el-button>
                <div slot="tip" class="el-upload__tip">&nbsp文件不大于3M</div>
              </el-upload>
            </div>
          </div>
          <!-- 表单操作 -->
          <div class="button-zone">
            <div>
              <span class="download-template" @click="templateDownload">{{title + '模板下载'}}</span>
            </div>
            <div class="btn-show">
              <div></div>
              <div>
                <el-button class="import-btn" type="primary" @click="submitUpload">导入</el-button>
                <el-button class="import-btn" @click="resetClick">重置</el-button>
              </div>
            </div>
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/**
 * 注释：
 *  1.该控件会在导入excel文件后响应json数据回来
 *  2.然后父组件可以@importBack方法中获取返回值
 *  3.为方便父组件操作，可以在父组件中为子组件设置dialogShow和loadingShow来控制弹窗和loading控件的显示和隐藏（注意：需要在字段后使用.sync,方便子控件双向绑定自更新父组件值）
 *  4.详细见例子：ITManage/duty/Duty.vue中63行
 */
import {importExcel, importExcelTemplate} from '@/api/baseApi'

export default {
  name: "excelImport",
  // 父组件传入参数
  props: {
    // 导入标题名称
    title: String,
    // 模块名
    moduleName:String,
    // 表名
    tableName:String,
  },
  data() {
    return {
      // 组件内部弹出框展示与否
      dialogVisible: false,
      // 文件列表
      fileList: [],
      // 加载中
      loading: false,
      // 模板下载地址
      templateUrl: "",
    }
  },
  methods: {
    // 改变上传文件
    changeUpload(file) {
      let index = file.name.lastIndexOf('.');
      let suffix = file.name.substring(index + 1).toUpperCase();
      if (suffix !== 'XLS' && suffix !== 'XLSX') {
        this.$message.warning('只支持导入excel文件');
        this.$refs.upload.clearFiles();
        return false;
      }
      this.fileList = [];
      this.fileList.push(file);
    },
    // 下载导入模板
    templateDownload() {
      importExcelTemplate(this.moduleName,this.tableName);
    },
    // 提交上传
    submitUpload() {
      if (this.fileList.length === 0) {
        this.$message.warning('请选择要导入的文件');
        return false;
      }
      this.loading = true;
      this.$refs.upload.submit();
    },
    // 重置点击
    resetClick() {
      this.fileList.pop()
    },
    // 自定义上传
    uploadExcel(file) {
      let formData = new FormData();
      formData.set("file", file.file);
      importExcel(this.moduleName,this.tableName,formData).then(res => {
        if(res.code===200){
          this.$message.success("excel导入成功")
          this.loading=false;
          this.dialogVisible = false;
        }else{
          this.loading=false;
          this.$message.warning('excel数据解析异常');
        }
      }).catch(() => {
          this.loading = false;
      })
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-dialog {
  border-radius: 6px;
}
.excelImport {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;

  .import-dialog {
    text-align: left;

    .container {
      .title {
        font-weight: bold;
        font-size: 0.35rem;
        border-bottom: 1px solid #ccc;
        padding-bottom: 0.2rem;
        margin-bottom: 0.4rem;
      }

      .info {
        display: flex;
        flex-wrap: wrap;
      }
      .button-zone {
        width: 100%;
        margin-top: 21px;
        display: flex;
        flex-direction: column;

        .btn-show{
          display: flex;
          align-items: center;
          justify-content: space-between;
          .import-btn {

          }
        }
        .download-template {
          color: #5C5C5C;
          font-size: 12px;
          border-bottom: 1px solid #5C5C5C;
          cursor: pointer;
        }
      }
    }
  }
}

.el-dialog__header {
  padding: 20px 20px 10px;
  background-color: #B3EBF5;
}
</style>