// 设置日期范围
export function setDateRange(queryParams, dateRange, propName) {
    queryParams.param = typeof (queryParams.param) === 'object' && queryParams.param !== null && !Array.isArray(queryParams.param) ? queryParams.param : {};
    dateRange = Array.isArray(dateRange) ? dateRange : [];
    //propName首字母大写
    propName=propName.trim().replace(propName[0], propName[0].toUpperCase());
    queryParams.param['lower' + propName] = dateRange[0];
    queryParams.param['upper' + propName] = dateRange[1];
    return queryParams;
}

/**
 * 获取id为id的子集懒加载列表
 * @param {*} data 全量数据列表
 * @param {*} id 主体id
 */
export function handleLazyTree(data,id) {
    //关键字段名配置
    let config = {
        id: 'id',
        parentId: 'parentId',
        hasChildren: 'hasChildren'
    };
    const list = [];
    for (let d of data) {
        if (d[config.parentId]===id) {
            list.push(d)
        }
    }
    //遍历树节点，添加完善其子节点list
    for (let t of list) {
        adaptToChildrenList(t,data);
    }
    function adaptToChildrenList(o,data) {
       o[config.hasChildren] = false;
       for (let d of data) {
           if (d[config.parentId]===o[config.id]) {
               o[config.hasChildren] = true;
           }
       }
    }
    return list;
}

/**
 * 构造树型结构数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 */
export function handleTree(data, id, parentId, children) {
    //关键字段名配置
    let config = {
        id: id || 'id',
        parentId: parentId || 'parentId',
        childrenList: children || 'children'
    };
    //key是parentId,value是node子节点
    const childrenListMap = {};
    //key是id,value是node节点
    const nodeIds = {};
    const tree = [];
    for (let d of data) {
        let parentId = d[config.parentId];
        if (childrenListMap[parentId] == null) {
            childrenListMap[parentId] = [];
        }
        nodeIds[d[config.id]] = d;
        childrenListMap[parentId].push(d);
    }
    //筛选出最上层节点
    for (let d of data) {
        let parentId = d[config.parentId];
        if (nodeIds[parentId] == null) {
            tree.push(d);
        }
    }
    //遍历树节点，添加完善其子节点list
    for (let t of tree) {
        adaptToChildrenList(t);
    }
    function adaptToChildrenList(o) {
        if (childrenListMap[o[config.id]] !== null) {
            o[config.childrenList] = childrenListMap[o[config.id]];
        }
        if (o[config.childrenList]) {
            for (let c of o[config.childrenList]) {
                adaptToChildrenList(c);
            }
        }
    }
    return tree;
}

/**
 * 动态注册，解绑vuex模块
 */
import store from '@/store/index'

/**
 * 注册vuex模块
 * @param moduleName
 * @param tableName
 * @param module
 */
export function registerStore(moduleName,tableName,module) {
    // 注册嵌套模块 `moduleName/tableName`
    store.registerModule([moduleName, tableName], module)
}

/**
 * 解绑vuex模块
 * @param moduleName
 * @param tableName
 */
export function unregisterStore(moduleName,tableName){
    if (store.hasModule([moduleName,tableName])) {
        store.unregisterModule([moduleName,tableName])
    }
}