<template>
  <div style="display: inline-block;">
    <el-button type="success"
               plain
               icon="el-icon-download"
               size="small"
               :loading="exportLoading"
               @click="exportExcel" v-permissionDisabled="permissionCode">
      全量导出</el-button>
  </div>
</template>

<script>
import {exportExcelAll} from "@/api/baseApi";

/**
 * 注释：excel导出控件，用于勾选导出excel(与原版差别在于权限码)
 * @author PeterLee
 * @since 2022.02.17
 */
export default {
  name: "ExcelExportPlus",
  props:{
    //模块名
    moduleName: String,
    //表名
    tableName: String,
    //导出文件名
    fileName:String,
    // 权限码
    permissionCode:String,
  },
  data(){
    return {
      //导出loading
      exportLoading: false,
    }
  },
  methods:{
    //导出
    exportExcel() {
      this.exportLoading=true;
      exportExcelAll(this.moduleName,this.tableName,this.fileName).then(()=>{
        this.exportLoading = false;
      }).catch(() => {
        this.exportLoading = false;
      })
    },
  }
}
</script>

<style scoped>

</style>