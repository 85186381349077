import {getNameById} from "@/api/baseApi";
import {dictionaryItems} from "@/api/systemApi"
import menu from "@/views/system/menu/MenuDataProvider";
import role from "@/views/system/role/RoleDataProvider";
import organization from "@/views/system/organization/OrganizationDataProvider";
import user from "@/views/system/user/UserDataProvider";

/**
 * 本模块的position子模块开始采用动态注册和动态销毁，目前可行性很高，没有任何问题
 */
export default {
    namespaced: true,
    modules: {
        menu,
        role,
        organization,
        user,
    },
    state: {
        /***************组织相关存储****************/
        //组织树
        organizationTree: [],
        //所有组织列表
        organizationList: [],
        /***************组织相关存储****************/
        //组织树
        knowledgeTypeTree: [],
        //所有组织列表
        knowledgeTypeList: [],
        /***************label相关存储（id对应name的类字典存储）****************/
        //用户名map,K:id,V:name
        userMap: {},
        positionMap: {},
        permissionMap: {},
        organizationMap: {},
        knowledgeTypeMap: {},
        /***************数据字典相关存储（value对应label）****************/
        get dictObject() {
            return JSON.parse(localStorage.getItem('dictObject'))
        },
        set dictObject(value) {
            localStorage.setItem('dictObject', JSON.stringify(value))
        },
    },
    getters: {},
    mutations: {
        /***************数据字典相关存储（value对应label）****************/
        //保存数据字典
        SET_DICTIONARY_OBJECT(state,data){
            state.dictObject=data;
        },
        /***************组织相关操作****************/
        SET_ORGANIZATION_TREE(state, data) {
            state.organizationTree = data;
        },
        /***************事件类型相关操作****************/
        SET_KNOWLEDGE_TYPE_LIST(state, data) {
            state.knowledgeTypeList = data;
        },
        /***************label相关操作****************/
        SET_USER_MAP(state, data) {
            state.userMap[data.id] = data.name;
        },
        SET_PERMISSION_MAP(state, data) {
            state.permissionMap[data.id] = data.name;
        },
        SET_POSITION_MAP(state, data) {
            state.positionMap[data.id] = data.name;
        },
        SET_ORGANIZATION_MAP(state, data) {
            state.organizationMap[data.id] = data.name;
        },
        SET_KNOWLEDGE_TYPE_MAP(state, data) {
            state.knowledgeTypeMap[data.id] = data.name;
        },
    },
    actions: {
        /***************数据字典相关请求****************/
        getDictionaryItems({commit,state},code){
            return new Promise((resolve,reject) =>{
                if(state.dictObject&&state.dictObject[code]){
                    resolve(state.dictObject[code])
                }
                if(code){
                    dictionaryItems(code).then(res=>{
                        if(res.code===200){
                            let dictObject = state.dictObject;
                            //判断对象是否为空，为空就定义
                            if(!dictObject){
                                dictObject={}
                            }
                            const options=[];
                            const list = res.data;
                            //改变字典项值的类型
                            if(list){
                                list.forEach(item=>{
                                    //字典类型为number,type为0时为String,默认为String,不用转类型
                                    options.push({value:item.value,label:item.label,style:item.style})
                                })
                            }
                            dictObject[code]=options;
                            commit('SET_DICTIONARY_OBJECT',dictObject);
                            resolve(options)
                        }else{
                            resolve([])
                        }
                    }).catch(error =>{
                        reject(error)
                    })
                }
            })
        },
        /***************label相关请求****************/
        getUserName({commit, state}, id) {
            return new Promise((resolve, reject) => {
                if (state.userMap[id]) {
                    resolve(state.userMap[id])
                }
                if (id) {
                    getNameById("system", "user", id).then(res => {
                        if (res.code === 200) {
                            commit('SET_USER_MAP', {"id": id, "name": res.data})
                            resolve(res.data)
                        }
                        resolve("加载失败")
                    }).catch(error => {
                        reject(error)
                    })
                }
            })
        },
        getPositionName({commit, state}, id) {
            return new Promise((resolve, reject) => {
                if (state.positionMap[id]) {
                    resolve(state.positionMap[id])
                }
                if (id) {
                    getNameById("system", "position", id).then(res => {
                        if (res.code === 200) {
                            commit('SET_POSITION_MAP', {"id": id, "name": res.data})
                            resolve(res.data)
                        }
                        resolve("加载失败")
                    }).catch(error => {
                        reject(error)
                    })
                }
            })
        },
        getOrganizationName({commit, state}, id) {
            return new Promise((resolve, reject) => {
                if (state.organizationMap[id]) {
                    resolve(state.organizationMap[id])
                }
                if (id) {
                    getNameById("system", "organization", id).then(res => {
                        if (res.code === 200) {
                            commit('SET_ORGANIZATION_MAP', {"id": id, "name": res.data})
                            resolve(res.data)
                        }
                        resolve("加载失败")
                    }).catch(error => {
                        reject(error)
                    })
                }
            })
        },
        getKnowledgeTypeName({commit, state}, id) {
            return new Promise((resolve, reject) => {
                if (state.knowledgeTypeMap[id]) {
                    resolve(state.knowledgeTypeMap[id])
                }
                if (id) {
                    getNameById("itil", "knowledgeType", id).then(res => {
                        if (res.code === 200) {
                            commit('SET_KNOWLEDGE_TYPE_MAP', {"id": id, "name": res.data})
                            resolve(res.data)
                        }
                        resolve("加载失败")
                    }).catch(error => {
                        reject(error)
                    })
                }
            })
        },
        getPermissionName({commit, state}, id) {
            return new Promise((resolve, reject) => {
                if (state.permissionMap[id]) {
                    resolve(state.permissionMap[id])
                }
                if (id) {
                    getNameById("system", "permission", id).then(res => {
                        if (res.code === 200) {
                            commit('SET_PERMISSION_MAP', {"id": id, "name": res.data})
                            resolve(res.data)
                        }
                        resolve("加载失败")
                    }).catch(error => {
                        reject(error)
                    })
                }
            })
        },
    }
}
