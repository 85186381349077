//webSocket请求路径
//const websocketUrl="ws://127.0.0.1:9000/api/system/websocket/"
// const logMessagesUrl="ws://10.143.132.113:9000/api/collectorServer/websocket/"
// const websocketUrl="ws://127.0.0.1:9000/api/system/websocket/"
//  const logMessagesUrl="ws://ncitjkb.ncntv.com.cn/api/system/websocket/"
// const logMessagesUrl="ws://192.168.10.13/api/system/websocket/"
let logMessagesUrl=  "ws://" + window.location.hostname
if(window.location.port !== ''){
    logMessagesUrl + ":" + window.location.port;
}
//const logMessagesUrl="ws://192.168.10.20/api/system/websocket/"
// const baseUrl="http://10.143.132.214:9000/api/system/websocket"
//export default websocketUrl
export default logMessagesUrl + '/api/system/websocket/'
