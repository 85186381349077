<!-- websocket通知组件 作者：PeterLee 最新更新时间：2021-9-23 -->
<template>
  <div class="liii">
    <el-popover
        placement="bottom-end"
        width="300"
        trigger="hover"
        v-model="popoverVisible">
      <el-tabs v-model="activeName" stretch>
        <el-tab-pane label="消息" name="message">
          <div style="height: 219px;overflow: auto;margin-bottom:10px;">
            <el-empty v-if="!messageList||messageList.length===0" description="暂无数据" :image="imgUrl"></el-empty>
            <el-card v-for="item in messageList" :key="item.id" shadow="hover" @click.native="cardClick(item)"
                     style="margin-bottom: 6px;">
              【{{item.fromName}}】{{item.title}}
            </el-card>
          </div>
          <div style="display: flex">
            <el-button size="small" style="flex: 1" @click="clickClear">清空</el-button>
            <el-button size="small" style="flex: 1" @click="clickMore">查看更多</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane label="通知" name="notice">
          <div style="height: 219px;overflow: auto;margin-bottom:10px;">
            <el-empty v-if="!noticeList||noticeList.length===0" description="暂无数据" :image="imgUrl"></el-empty>
            <el-card v-for="item in noticeList" :key="item.id" shadow="hover" @click.native="cardClick(item)"
                     style="margin-bottom: 6px;">
              【{{item.fromName}}】{{item.title}}
            </el-card>
          </div>
          <div style="display: flex">
            <el-button size="small" style="flex: 1" @click="clickClear">清空</el-button>
            <el-button size="small" style="flex: 1" @click="clickMore">查看更多</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane label="告警" name="alert">
          <div style="height: 219px;overflow: auto;margin-bottom:10px;">
            <el-empty v-if="!alertList||alertList.length===0" description="暂无数据" :image="imgUrl"></el-empty>
            <el-card v-for="item in alertList" :key="item.id" shadow="hover" @click.native="cardClick(item)"
                     style="margin-bottom: 6px;">
              【{{item.fromName}}】{{item.title}}
            </el-card>
          </div>
          <div style="display: flex">
            <el-button size="small" style="flex: 1" @click="clickClear">清空</el-button>
            <el-button size="small" style="flex: 1" @click="clickMore">查看更多</el-button>
          </div>
        </el-tab-pane>
      </el-tabs>
      <el-badge :value="badgeNum" :hidden="badgeHidden" :type="badgeType" :max="99" slot="reference">
<!--                style="margin-right:24px;margin-left: 12px;"-->
        <el-button circle icon="el-icon-bell" size="mini" @click="popoverVisible=!popoverVisible"/>
      </el-badge>
    </el-popover>
    <el-dialog :visible.sync="dialogVisible" width="560px" :close-on-click-modal="false" class="detail-dialog">
      <div class="dialog-container">
        <div class="title">
          {{title}}
        </div>
        <div class="content">
          {{content}}
        </div>
        <div class="footer">
          <el-button type="primary" :disabled="backDisabled">填写回执</el-button>
          <el-button type="primary" :disabled="toDisabled">前往处理</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import websocketUrl from "@/config/socketUrl"

export default {
  name: "WebSocket",
  data() {
    return {
      activeName: "notice",
      imgUrl: require('../../static/empty.png'),
      //popover显示
      popoverVisible: false,
      //消息未读数目
      badgeNum: 0,
      //消息数目隐藏
      badgeHidden: false,
      //消息类型
      badgeType: "primary",
      //消息列表
      messageList: [{
        fromName: "系统",
        title: "n31002号主机需要扩容"
      }, {
        fromName: "系统",
        title: "n31002号主机需要扩容"
      }, {
        fromName: "系统",
        title: "n31002号主机需要扩容"
      }, {
        fromName: "系统",
        title: "n31002号主机需要扩容"
      }],
      //通知列表
      noticeList: [],
      //告警列表
      alertList: [],

      //dialog弹出层
      dialogVisible: false,
      //标题
      title: "系统容量告警",
      //内容
      content: "近日，北京出台《北京市引进毕业生管理办法》，发出“计划单列“式抢人的大招：对于全市重点发展领域，面向世界大学综合排名前200位的国内高校本科及以上学历毕业生，按照计划单列办理引进。",
      //回执禁用
      backDisabled: false,
      //前往禁用
      toDisabled: false,

      // ws是否启动
      wsIsRun: false,
      // 定义ws对象
      webSocket: "",
      // ws请求链接（类似于ws后台地址）
      ws: '',
      // ws定时器
      wsTimer: null,
      //通知弹窗map
      notifications: {}
    }
  },

  mounted() {
    //链接socket
    this.connWebSocket();
  },
  beforeDestroy() {
    // 监听窗口关闭事件,vue生命周期销毁之前关闭socket当窗口关闭时，防止连接还没断开就关闭窗口。
    this.onbeforeunload();
  },
  methods: {
    //点击卡片
    cardClick(item) {
      this.dialogVisible = true;
      this.title = item.title;
    },
    //点击查看更多
    clickMore(){
      this.popoverVisible=false;
      this.$router.push('/system/userMessage')
    },
    //点击清空
    clickClear(){
      this.popoverVisible=false;
      this.$router.push('/system/notice')
    },
    connWebSocket() {
      // WebSocket
      if ("WebSocket" in window) {
        this.websocket = new WebSocket(
            //userToken 传此值主要后端java用来保存session信息
            websocketUrl + `${this.$store.state.UserToken}`
        );

        //初始化socket
        this.initWebSocket();
      } else {
        this.$message.error("此浏览器不支持websocket");
      }
    },
    initWebSocket() {
      // 连接错误
      this.websocket.onerror = this.setErrorMessage;

      // 连接成功
      this.websocket.onopen = this.setOnopenMessage;

      // 收到消息的回调
      this.websocket.onmessage = this.setOnmessageMessage;

      // 连接关闭的回调
      this.websocket.onclose = this.setOncloseMessage;

      // 监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
      window.onbeforeunload = this.onbeforeunload;
    },
    setErrorMessage() {
      console.log(
          "WebSocket连接发生错误   状态码：" + this.websocket.readyState
      );
    },
    setOnopenMessage() {
      console.log("WebSocket连接成功    状态码：" + this.websocket.readyState);
    },
    setOnmessageMessage: function (result) {
      console.log("服务端返回：" + result.data);
      let msgMap = JSON.parse(result.data);
      let id = msgMap.id;
      //系统通知，来自“系统管理员"，“定时任务"
      let title = msgMap.title;
      let type = msgMap.type;
      let text = msgMap.text;
      this.badgeNum=msgMap.num;
      this.badgeType=type==="message"?"success":type==="notice"?"warning":"error";
      this.activeName=type;
      // 根据服务器推送的消息做自己的业务处理
      const h = this.$createElement
      this.notifications[id] = this.$notify({
        title: title,
        type: type==="message"?"success":type==="notice"?"warning":"error",
        duration: 0,
        dangerouslyUseHTMLString: true,
        message:
            h('div', {class: "container"}, [
              h('div', {class: "content"}, [text]),
              h('div', {class: "footer"}, [
                h('div', {class: "createTime"}, [""]),
                h('div', {
                  class: "url", on: {
                    click: this.clickUrl.bind(this,id),
                  },
                }, [
                  h('el-link', {}, ["点击查看详情"]),
                  h('span', {class: "el-icon-arrow-right"}, []),
                ])
              ])
            ]),
        position: "bottom-right",
        offset: 10
      });
    },
    setOncloseMessage(e) {
      console.log('websocket 断开: ' + e.code + ' ' + e.reason + ' ' + e.wasClean)
      console.log("WebSocket连接关闭    状态码：" + this.websocket.readyState);
    },
    onbeforeunload() {
      this.closeWebSocket();
    },
    closeWebSocket() {
      this.websocket.close();
    },
    clickUrl(id) {
      // window.location.href = 'http://www.baidu.com'  //当前页面打开
      // window.open('https://www.baidu.com')
      //跳转至通知管理页面
      // this.$router.push('/system/notice')
      this.notifications[id].close();
      this.popoverVisible=true;
    }
  }
}
</script>
<style>
.el-notification__group {
  flex: 1;
}
</style>
<style lang="less" scoped>

.el-empty /deep/ .el-empty__image {
  width: 100px;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;

  .content {
    margin-top: 5px;
  }

  .footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;

    .createTime {

    }

    .url {
      display: flex;
      align-items: center;
    }
  }
}

.detail-dialog {

  .dialog-container {
    display: flex;
    align-items: center;
    justify-items: center;
    margin: 0 30px;
    flex-direction: column;
    min-height: 250px;
    max-height: 560px;

    .title {
      font-weight: bolder;
      font-size: large;
      margin-bottom: 20px;
    }

    .content {
      flex: 1;
      font-size: medium;
      margin-bottom: 20px;
    }

    .footer {
      width: 80%;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>