import Vue from "vue"
import Vuex from "vuex"
Vue.use(Vuex)
import system from '@/store/modules/system'
import user from "@/store/modules/user"
import permission from "@/store/modules/permission";
import data from "@/store/modules/data";

/**
 * vuex的root层级，本级可以对各个部分进行了拆分，便于管理，
 * 注：下辖的modules为该项目的主要模块
 * @author PeterLee
 * @date 2021-12-14
 */
export default new Vuex.Store({
	//modules是将全局变量分组，便于管理，将下列模块引入此文件中
	modules: {
		system,
		user,
		permission,
		data,
	},
    //所有的数据都放在state中
    state: {
		//全局使用，页面跳转参数缓存
		get pushParams(){
			return JSON.parse(localStorage.getItem('pushParams'))
		},
		set pushParams(value){
			return localStorage.setItem('pushParams',value)
		},
    },
	//获取数据
	getters: {
		routers: state => state.permission.routes
	},
    //操作数据，唯一的通道是mutations
    mutations: {
		SET_PUSH_PARAMS(state,params){
			state.pushParams = params
		},
    },
    //actions,可以来做异步操作，然后提交给mutations，而后再对state(数据)进行操作
    actions: {
		setPushParams({state,commit},params){
			commit('SET_PUSH_PARAMS',JSON.stringify(params))
		},
	}
})