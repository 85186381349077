<!-- 用户名展示标签（用于将用户id转为用户名） 事例：ITManage->TrainingRecord中表格‘创建人’字段有用到，可以参考 作者：PeterLee 最近修改时间：2021.9.16-->
<template>
  <div>
    {{name}}
  </div>
</template>

<script>
export default {
  name: "UserNameLabel",
  //设置v-model绑定对象和自动更新响应方法
  model: {
    prop: 'id',
  },
  props: {
    id: String,
  },
  data() {
    return {
      //姓名显示
      name: ""
    };
  },
  watch: {
    id: function (newVal) {
      if (newVal) {
        this.getUserName();
      } else {
        this.name = undefined;
      }
    }
  },
  methods: {
    //获取用户名
    getUserName() {
      this.$nextTick(() => {
        this.$store.dispatch('system/getUserName', this.id).then(res => {
          this.name = res;
        })
      })
    }
  },
  mounted() {
    this.getUserName();
  }
}
</script>

<style scoped>

</style>