<!-- 登录账户组件 作者：PeterLee 最新更新时间：2022-01-24 -->
<template>
  <div>
    <el-dropdown @command="handleCommand">
      <div style="display: flex;">
        <el-button circle icon="el-icon-user" size="mini" style="margin-left: 8px;"/>
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item icon="el-icon-user" command="info">个人信息</el-dropdown-item>
        <el-dropdown-item icon="el-icon-edit-outline" command="password">修改密码</el-dropdown-item>
        <el-dropdown-item icon="el-icon-back" command="exit">退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <base-form :isAdd="true" :relationDialogVisible.sync="dialogVisible" showTitle="修改密码"
               :rules="rules" @submitAdd="changePassword" @closeDialog="closeDialog" width="32%"
               ref="baseForm" defaultEmpty>
      <!-- 插槽部分 -->
      <template v-slot:default="{ruleForm}" class="info">
        <el-form-item label="原密码" prop="oldPassword">
          <el-input type="password" v-model="ruleForm.oldPassword" show-password></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input type="password" v-model="ruleForm.newPassword" show-password></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="againPassword">
          <el-input type="password" v-model="ruleForm.againPassword" show-password></el-input>
        </el-form-item>
      </template>
    </base-form>
    <!-- 添加展示个人信息的弹框 -->
<!--    <el-dialog :visible.sync="infoDialogVisible" title="个人信息">-->

<!--    </el-dialog>-->
    <base-form-plus :relationDialogVisible.sync="infoDialogVisible" showTitle="个人信息" :showData="userInfo" :rules="AccountRules" @submitEdit="submitEdit" ref="baseForm">
      <!-- 插槽部分 -->
      <template v-slot:default="{ruleForm}" class="info">
        <!-- 用户名 -->
        <el-form-item label="用户名" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <!-- 登录名 -->
        <el-form-item label="登录名"  prop="loginName" >
          <el-input v-model="ruleForm.loginName" disabled></el-input>
        </el-form-item>
        <!-- 组织id -->
        <el-form-item label="组织" prop="organizationId">
          <el-input v-model="ruleForm.organizationName" disabled></el-input>
        </el-form-item>
        <!-- 职务id -->
        <el-form-item label="职务" prop="positionId">
          <el-input v-model="ruleForm.positionName" disabled></el-input>
        </el-form-item>
        <!-- 手机号码 -->
        <el-form-item label="手机号码" prop="mobile">
          <el-input v-model="ruleForm.mobile"></el-input>
        </el-form-item>
        <!-- 邮箱 -->
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="ruleForm.email"></el-input>
        </el-form-item>
      </template>
    </base-form-plus>
  </div>
</template>

<script>
/**
 * 登录账号管理组件（个人信息，修改密码，退出登录）
 * @author PeterLee
 * @since 2022-01-24
 */
import BaseForm from "@/components/widget/BaseForm";
import {changePassword, updateCurrentUser} from "@/api/systemApi";
import {Message} from "element-ui";
import {validateEmail, validatePhone} from "@/utils/validateUtil";

export default {
  name: "LoginAccount",
  components: {BaseForm},
  data() {
    return {
      infoDialogVisible: false, // 控制个人信息弹框的显示与隐藏
      userInfo: {}, // 用于存储个人信息的变量
      options: {
        //组织树（选项）
        organizationTreeOptions:undefined,
        //职位列表
        positionOptions:undefined
      },
      AccountRules:{
        //用户名
        name:[{
          required: true,
          message: '请输入用户名',
          trigger: 'blur'
        },
          {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
          }
        ],
        //手机号码
        mobile:[{
          validator: validatePhone,
          required: true,
          message: '请输入手机号码',
          trigger: 'blur'
        }],
        //邮箱
        email:[
          {
            validator: validateEmail,
            required: true,
            message: '请输入邮箱',
            trigger: 'blur'
          }
        ]},
      dialogVisible:false,
      rules:{
        oldPassword:[{
          required: true,
          message: '请输入原密码',
          trigger: 'blur'
        },
          {
            min: 1,
            max: 30,
            message: '长度在 1 到 30 个字符',
            trigger: 'blur'
          }
        ],
        newPassword:[{
          required: true,
          message: '请输入新密码',
          trigger: 'blur'
        },
          {
            min: 1,
            max: 30,
            message: '长度在 1 到 30 个字符',
            trigger: 'blur'
          }
        ],
        againPassword:[{
          required: true,
          message: '请输入确认密码',
          trigger: 'blur'
        },
          {
            min: 1,
            max: 30,
            message: '长度在 1 到 30 个字符',
            trigger: 'blur'
          }
        ],
      }
    }
  },
  created() {
    this.getInfo(); // 调用获取信息的方法
  },
  methods: {
    handleCommand(command) {
      if (command === 'exit') {
        this.handleLogout();
      }
      if(command === 'password'){
        this.dialogVisible=true;
      }
      if(command === 'info') {
        // 显示个人信息弹框
        this.infoDialogVisible = true;
      }
    },
    //退出登录
    handleLogout() {
      this.$systemApi.logout().then(res => {
        //清除token
        if (res.code === 200) {
          // this.$store.commit('LOGIN_OUT')
          this.$store.dispatch('user/logout')
        }
      })
    },
    //修改密码
    changePassword(data) {
        if(data.oldPassword===data.newPassword){
           this.$message.error("新旧密码相同，请重新输入")
           return;
        }
        if(data.againPassword!==data.newPassword){
           this.$message.error("两次输入的密码不相等")
           return;
        }
      changePassword(data).then(res=>{
         if(res.code===200){
            this.$message.success("密码修改成功")
            // this.$refs.baseForm.setVisible(false);
           this.closeDialog();
         }
      })
    },
    getInfo() {
      this.userInfo = this.$store.getters["user/getUserInfo"];
      this.userInfo.organizationName = this.userInfo.organization.name;
    },
    /** 编辑提交后执行内容 (异步网络请求)*/
    submitEdit(data) {
      let user = {
         "name": data.name,
         "mobile": data.mobile,
         "email": data.email
      }
      updateCurrentUser(user).then(res => {
        if (res.code === 200) {
          //关闭弹窗
          Message.success("修改个人信息成功")
          this.infoDialogVisible = false;
          // 刷新当前页(重新获取currentUserInfo) location.reload();
          this.$router.go(0);
        }
      });
    },
    closeDialog(){
        this.dialogVisible=false;
    }
  }
}
</script>

<style lang="less" scoped>
.info {
  display: flex;
  flex-wrap: wrap;

  .el-form-item {
    width: 50%;
  }
}
</style>