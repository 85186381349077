/**
 * 系统角色表状态管理（vuex的state可以单独出来，便于管理，同样mutations和actions都可以提取出来）
 *
 * @author PeterLee
 * @since 2022-03-26
 */
const state = {
   //表单校验规则
   rules: {
      //角色名
      name:[{
         required: true,
         message: '请输入角色名',
         trigger: 'blur'
      },
        {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
        }
     ],
      //角色标识
      code:[{
         required: true,
         message: '请输入角色标识',
         trigger: 'blur'
      },
        {
            min: 1,
            max: 32,
            message: '长度在 1 到 32 个字符',
            trigger: 'blur'
        }
     ],
      //角色描述
      description:[{
         required: true,
         message: '请输入角色描述',
         trigger: 'blur'
      },
        {
            min: 1,
            max: 255,
            message: '长度在 1 到 255 个字符',
            trigger: 'blur'
        }
     ],
      //角色类型 0默认角色 1自建角色
      type:[{
         required: true,
         message: '请选择角色类型',
         trigger: 'change'
      }],
      //数据权限范围
      dataScope:[{
         required: true,
         message: '请选择数据权限范围',
         trigger: 'change'
      }],
      //状态 0：无效 1：有效
      state:[{
         required: true,
         message: '请选择状态',
         trigger: 'change'
      }],
   },
   //列信息
   columnInfo: {
       //角色名
       name:{
            visible:true,
            label:"角色名"
       },
       //角色标识
       code:{
            visible:true,
            label:"角色标识"
       },
       //角色描述
       description:{
            visible:true,
            label:"角色描述"
       },
       //角色类型 0默认角色 1自建角色
       type:{
            visible:true,
            label:"角色类型"
       },
       //数据权限范围
       dataScope:{
            visible:true,
            label:"数据权限范围"
       },
       //状态 0：无效 1：有效
       state:{
            visible:true,
            label:"状态"
       },
   },
   //表格数据（查询结果）
   tableData: [],
   //共有数据条数（分页控件）
   totalCount: 0,
   //选项列表（列表下拉框选择）
   options: {},
   //查询参数
   queryParams: {
      //当前页数（分页控件）
      currPage: 1,
      //每页条数（分页控件）
      limit: 10,
      //是否升序
      ascOrder: false,
      //排序字段
      orderField: "",
      //此处为可能需要修改部分，请不要忽略
      param: {
         //角色名
         name: undefined,
         //角色标识
         code: undefined,
         //角色描述
         description: undefined,
         //角色类型 0默认角色 1自建角色
         type: undefined,
         //数据权限范围
         dataScope: undefined,
         //状态 0：无效 1：有效
         state: undefined,
      }
   },
   //时间范围
   dateRange: {

   },
   //表单组件信息
   formInfo: {
      //表单组件弹出框显示控制
      dialogVisible: false,
      //是否增加操作（非增加操作会隐藏部分操作）
      isAdd: false,
      //编辑回显数据
      editData: {},
   },
   //更多按钮信息
   moreButtonProps:[
      {
          command:'dataScope',
          label:'数据权限',
          permissionCode:'itil:event'
      },
       {
           command:'menuPermission',
           label:'菜单权限',
           permissionCode:'itil:event'
       }
   ],
   //数据权限选择框
   dataScopeSelect: {
       dialogVisible: false,
       roleName: undefined,
       roleCode: undefined,
       roleDataScope: undefined
   }
}
export default state