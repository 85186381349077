<template>
  <div>
    <el-select v-model="innerValue" :placeholder="placeholder" style="width: 100%;" :size="size" :clearable="clearable" :disabled="disabled">
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>

<script>
/**
 * 枚举字典选择器
 * 本控件使用方法：
 *      1.需要配合后端枚举信息查询接口和前端vuex的store/modules/system.js来实现
 *      2.首先在后端代码中写入枚举类，记录其编码
 *      3.然后使用vue中搜索栏“状态"已有事例可供参考
 *      4.注意：枚举字典项下拉框需要将校验规则中的长度校验去除，校验方式改为“change”，否则会报错，即“数据提供者"中的rules
 *  @author PeterLee
 *  @since 2022-02-10
 */
export default {
  name: "EnumDictSelect",
  //设置v-model绑定对象和自动更新响应方法
  model: {
    prop: 'dValue',
    event:'change',
  },
  props: {
    dValue: {
      type: [String,Number],
      require:true
    },
    //字典编码
    dictCode: {
      type:String,
      default:""
    },
    placeholder:{
      type:String,
      default: "请选择"
    },
    size:{
      type:String,
      default: "middle"
    },
    clearable:{
      type:Boolean,
      default:false
    },
    //传入模块名（微服务模块名称）
    moduleName: {
      type: String,
      default: "system"
    },
    echoValue:String,
    disabled:{
      type:Boolean,
      default:false
    },
  },
  watch: {
    dValue:{
      handler(val){
        if(val!==null&&val!==undefined){
          this.getOptions()
        }else{
          this.innerValue=null;
        }
      }
    },
    innerValue:{
      handler(newVal,oldVal){
        if(newVal!==oldVal){
          this.$emit("change",newVal)
        }
      }
    },
    dictCode:{
      handler(){
        this.getOptions()
        this.innerValue = ''
      }
    },
    echoValue:{
      handler(val){
        this.$emit('change',val);
      }
    },
  },
  data() {
    return {
      innerValue:"",
      //选项
      options:[],
    };
  },
  methods:{
    getOptions(){
      this.$nextTick(()=>{
        this.$store.dispatch('user/getEnumDictItems',{moduleName:this.moduleName,code:this.dictCode}).then(res => {
          this.options = res;
          if(this.dValue!==undefined&&this.dValue!==null){
            this.innerValue=String(this.dValue);
          }
        })
      })
    },
  },
  mounted() {
    this.getOptions()
  }
}
</script>

<style scoped>

</style>