<template>
  <div class="top-right-btn">
    <el-row>
      <el-tooltip class="item" effect="dark" :content="showSearch ? '隐藏搜索' : '显示搜索'" placement="top">
        <el-button size="mini" circle icon="el-icon-search" @click="toggleSearch()" />
      </el-tooltip>
      <el-tooltip class="item" effect="dark" content="刷新" placement="top">
        <el-button size="mini" circle icon="el-icon-refresh" @click="refresh()" />
      </el-tooltip>
      <el-popover
          style="margin-left: 10px"
          placement="bottom-end"
          title="勾选显示"
          width="120"
          trigger="click">
        <div style="display: flex;flex-direction: column;">
          <el-checkbox :indeterminate="indeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
          <el-checkbox v-for="(value,key) in columnInfo" :key="key" v-model="value.visible" @change="handleCheckChange">{{value.label}}</el-checkbox>
        </div>
        <el-tooltip class="item" effect="dark" content="显隐列" placement="top" v-if="columnInfo" slot="reference">
          <el-button size="mini" circle icon="el-icon-menu"/>
        </el-tooltip>
      </el-popover>
    </el-row>
  </div>
</template>

<script>
/**
 * 右边顶部工具栏
 * @author PeterLee
 * @since 2021-09-22
 */
export default {
  name: "RightToolbar",
  data() {
    return {
      checkAll:true,
      indeterminate:false
    };
  },
  props: {
    showSearch: {
      type: Boolean,
      default: true,
    },
    columnInfo: {
      type: Object,
    },
  },
  created() {

  },
  methods: {
    // 搜索
    toggleSearch() {
      this.$emit("update:showSearch", !this.showSearch);
    },
    // 刷新
    refresh() {
      this.$emit("queryTable");
    },
    // 处理全选事件
    handleCheckAllChange(val) {
      for(let key in this.columnInfo){
         if(this.columnInfo.hasOwnProperty(key)){
           this.columnInfo[key].visible=val;
         }
      }
      this.indeterminate=false;
    },
    // 选择改变事件
    handleCheckChange(value) {
      // 隐藏个数
      let num=0;
      for(let key in this.columnInfo){
        if(this.columnInfo.hasOwnProperty(key)){
          if(!this.columnInfo[key].visible){
            num++;
          }
        }
      }
      if(value){
        if(num===0){
          this.indeterminate=false;
          this.checkAll=true;
        }else{
          this.checkAll=false;
          this.indeterminate=true;
        }
      }else{
        if(num===Object.keys(this.columnInfo).length){
          this.checkAll=false;
          this.indeterminate=false;
        }else{
          this.checkAll=false;
          this.indeterminate=true;
        }
      }
    }
  },
};
</script>
<style lang="less" scoped>
::v-deep .el-transfer__button {
  border-radius: 50%;
  padding: 12px;
  display: block;
  margin-left: 0px;
}
::v-deep .el-transfer__button:first-child {
  margin-bottom: 10px;
}
.top-right-btn {
  position: relative;
  float: right;
}
</style>
