/**
 * 数据组件表    状态管理（vuex的state可以单独出来，便于管理，同样mutations和actions都可以提取出来）
 *
 * @author PeterLee
 * @since 2022-07-21
 */
const state = {
    //表单校验规则
    rules: {
        //组件名称
        name: [{
            required: true,
            message: '请输入组件名称',
            trigger: 'blur'
        },
            {
                min: 1,
                max: 20,
                message: '长度在 1 到 20 个字符',
                trigger: 'blur'
            }
        ],
        //组件模板代码
        word: [{
            required: false,
            message: '请输入组件模板代码',
            trigger: 'blur'
        },
            {
                min: 1,
                max: 500,
                message: '长度在 1 到 500 个字符',
                trigger: 'blur'
            }
        ],
        //组件类型
        type: [{
            required: true,
            message: '请选择组件类型',
            trigger: 'change'
        }],
        //组件备注
        comment: [{
            required: true,
            message: '请输入组件备注',
            trigger: 'blur'
        },
            {
                min: 1,
                max: 20,
                message: '长度在 1 到 20 个字符',
                trigger: 'blur'
            }
        ],
        //是否默认
        isDefault: [{
            required: true,
            message: '请选择',
            trigger: 'change'
        }],
        //状态
        state: [{
            required: true,
            message: '请输入状态',
            trigger: 'change'
        }],
    },
    //列信息
    columnInfo: {
        //组件名称
        name: {
            visible: true,
            label: "组件名称"
        },
        //组件模板代码
        word: {
            visible: true,
            label: "组件模板代码"
        },
        //组件类型
        type: {
            visible: true,
            label: "组件类型"
        },
        //组件备注
        comment: {
            visible: true,
            label: "组件备注"
        },
        //是否默认
        isDefault: {
            visible: true,
            label: "是否默认"
        },
        //状态
        state: {
            visible: true,
            label: "状态"
        },
    },
    //表格数据（查询结果）
    tableData: [],
    //共有数据条数（分页控件）
    totalCount: 0,
    //选项列表（列表下拉框选择）
    options: {},
    //查询参数
    queryParams: {
        //当前页数（分页控件）
        currPage: 1,
        //每页条数（分页控件）
        limit: 10,
        //是否升序
        ascOrder: false,
        //排序字段
        orderField: "",
        //此处为可能需要修改部分，请不要忽略
        param: {
            //组件名称
            name: undefined,
            //组件模板代码
            word: undefined,
            //组件类型 0：输入型组件 1：展示型组件
            type: undefined,
            //组件备注
            comment: undefined,
            //是否默认
            isDefault: undefined,
            //状态
            state: undefined,
        }
    },
    //时间范围
    dateRange: {},
    //表单组件信息
    formInfo: {
        //表单组件弹出框显示控制
        dialogVisible: false,
        //是否增加操作（非增加操作会隐藏部分操作）
        isAdd: false,
        //编辑回显数据
        editData: {},
    },
    //更多按钮信息
    moreButtonProps: [
        //{command:'test',label:'示例按钮',permissionCode:'itil:event'}
    ]
}
export default state