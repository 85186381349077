<!-- 人员选择器 作者：PeterLee 最新更新时间：2021-9-6 -->
<template>
	<el-dialog v-dialogDrag title="人员选择" :visible.sync="dialogVisible" @close="clickDialogClose" center width="976px" top="60px" append-to-body >
		<div class="container">
			<!-- 组织树区域 -->
			<div class="left" style="display: flex;flex-direction: column;">
				<div style="display: flex;align-items: center;justify-content: space-between;padding:0.4rem;width: 200px;">
					<div style="font-weight: bold;flex: 1;font-size: 0.3rem;">
						用户组织
					</div>
          <div style="flex: 1;">
            <el-button type="primary" size="small"  icon="el-icon-refresh-left" @click="clickRefreshData()">刷新</el-button>
          </div>
				</div>
				<!-- 组织树 -->
				<div style="flex: 1;overflow: auto;">
          <el-tree :data="organizationTree" :props="defaultProps" @node-click="handleNodeClick" style="overflow: auto;"
                   :render-content="renderContent" highlight-current default-expand-all></el-tree>
        </div>
			</div>
			<!-- 人员选择区域 -->
			<div class="center">
				<div
					style="font-size: 0.3rem;font-weight: bold;margin: 0.2rem;display: flex;justify-content: space-between;">
					<el-input v-model="check.name" placeholder="请输入用户名"></el-input>
					<el-input v-model="check.loginName" placeholder="请输入登录名" style="margin-left: 0.2rem;"></el-input>
					<el-button type="primary" @click="handleTableCheck()" style="margin-left: 0.2rem;">查询</el-button>
				</div>
				<el-table class="table" border ref="multipleTable" :data="tableData" tooltip-effect="dark"
					 @select="handleSelect" @select-all="handleSelectAll" :highlight-current-row="isSingleSelected" @current-change="handleSingleSelectChange">
					<el-table-column align="center" type="selection" v-if="!isSingleSelected">
					</el-table-column>
					<el-table-column align="center" prop="name" label="用户名">
					</el-table-column>
					<el-table-column align="center" prop="loginName" label="登录名">
					</el-table-column>
					<el-table-column align="center" prop="mobile" label="手机号码" width="155">
					</el-table-column>
				</el-table>
				<div class="bottom">
					<MyPagination :totalCount="totalCount" :pageSize="pageSize" :currPage="currPage"
						:pageSizes="pageSizes" :showState="false" :showDelAll="false"
						@handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></MyPagination>
				</div>
			</div>
			<!-- 已选人员区域 -->
			<div class="right">
				<!-- 人员展示item 问题遗留：用户名过长问题、过多滚动问题（待解决） -->
        <div style="font-size: 0.3rem;font-weight: bold;margin: 0.4rem;">
          已选人员
        </div>
        <div class="content" style="overflow: auto;flex:1;">
					<div class="user-item" v-for="(item,index) in selectList" :key="item.id">
						<div style="display: flex;">
							<div>
								{{item.name}}
							</div>
							<div style="margin-left: 0.1rem;">
								({{item.loginName}})
							</div>
						</div>
						<div>
							<i class="el-icon-circle-close" style="color: #F56C6C;font-size: 0.3rem;"
								@click="cancelSelect(index)"></i>
						</div>
					</div>
				</div>
				<div class="bottom">
					<el-button type="primary" @click="userBack">确认</el-button>
					<el-button @click="clearSelectList">清空</el-button>
				</div>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import MyPagination from "@/components/My-pagination"
	export default {
		name: "UserSelector",
		props: {
			//控制弹出框显示
			selectorVisible: Boolean,
			//是否单选
			isSingleSelected:{
				type:Boolean,
				default:false
			},
      //传入用户id列表
      data:Array
		},
		components: {
			MyPagination
		},
		watch: {
			//监视父组件是否有指令需要重置页面
			selectorVisible: {
				handler: function(newVal) {
					if (newVal) {
						//更新时刷新表单校验信息
						this.dialogVisible = true;
						//更新回显数据
						this.$nextTick(() => {

						});
					}else{
					  this.dialogVisible = false;
          }
				}
			},
      //监听传入数据的变化，将其放入已选list
      data: {
			  handler: function (newVal){
			      if(newVal!==undefined&&newVal!==null&&newVal.length!==0){
			          this.selectList=[];
                newVal.forEach(item=>{
                    this.selectList.push({id:item})
                })
            }else{
			          this.selectList=[];
            }
			      //请求数据，触发回显
            this.handleTableCheck();
        }
      }
		},
    mounted() {

    },
		data() {
			return {
				//组件内部弹出框展示与否
				dialogVisible: false,
				//表单数据
				ruleForm: {},
				//组织树
				organizationTree: [],
				//组织树映射
				defaultProps: {
					children: 'childrenList',
					label: 'name'
				},
				//检索内容
				check: {

				},
				//表格数据
				tableData: [],
				//批量操作list(多选)
				selectList: [],
				//选中的treeData
				checkTreeData: {},
				//共有数据条数（分页控件）
				totalCount: 0,
				//当前页数（分页控件）
				currPage: 1,
				//每页条数（分页控件）
				pageSize: 20,
				//每页条数选项（分页控件）
				pageSizes: [10, 20, 40, 80],
			}
		},
		//生命周期create函数
		created() {
			//创建时获取树
			this.getOrganizationTree();
			//创建时获取所有用户数据（分页查询）
			this.handleTableCheck();
		},
		methods: {
			//树的图标分级
			renderContent: function(h, {
				node,
				data,
				store
			}) {
				let addElement = arguments[0];
				if (data.level === 1) {
					return addElement('span', [
						addElement('i', {
							class: "el-icon-location-outline"
						}),
						addElement('span', "    "),
						addElement('span', arguments[1].node.label)
					]);
				} else if (data.level === 2) {
					return addElement('span', [
						addElement('i', {
							class: "el-icon-office-building"
						}),
						addElement('span', "    "),
						addElement('span', arguments[1].node.label)
					]);
				} else {
					return addElement('span', [
						addElement('i', {
							class: "el-icon-copy-document"
						}),
						addElement('span', "    "),
						addElement('span', arguments[1].node.label)
					]);
				}
			},

			/*************************************操作触发事件***********************************/
			//弹出框关闭
			clickDialogClose() {
				this.$emit("update:selectorVisible", false);
			},
			//处理树节点点击事件
			handleNodeClick(checkData) {
				this.checkTreeData = checkData;
				//通过组织id查询用户表
				this.handleTableCheck();
			},
			//处理选择框改变（表格）多选
			handleSelect(rows,row) {
        //当前行的选中状态
        let selected = rows.length && rows.findIndex(item=>item.id===row.id) !== -1;
        if(selected){
          //选中，则添加到选中list中(不存在则加)
          if(this.selectList.findIndex(item=>item.id===row.id)===-1){
            this.selectList.push(row);
          }
        }else{
          //取消选中时，则从选中list中清除这一个数据
          const index = this.selectList.findIndex(item=>item.id===row.id);
          if (index > -1) {
            this.selectList.splice(index, 1);
          }
        }
			},
      //处理点击全选
      handleSelectAll(rows){
			    //全选时
			    if(rows.length>0){
			       //批量加入，存在则不加入，不存在则加入
             rows.forEach(row=>{
               if(this.selectList.findIndex(item=>item.id===row.id)===-1){
                  this.selectList.push(row);
               }
             })
          }else{
             //批量清除，遍历全清
             this.tableData.forEach(row=>{
               this.selectList.splice(this.selectList.findIndex(item=>item.id===row.id),1);
             })
          }
      },
			//处理单选改变（单选）
			handleSingleSelectChange(raw){
				if(this.isSingleSelected){
					 if(raw!=null){ 
						this.selectList.splice(0,1,raw);
					 }
				}
			},
			//右边点击x按钮触发事件
			cancelSelect(index) {
				if(this.isSingleSelected){
				  //取消高亮
					this.$refs.multipleTable?.setCurrentRow();
					//清空list
					this.selectList=[];
				}else{
					this.$refs.multipleTable.toggleRowSelection(this.tableData.find(item=>this.selectList[index].id===item.id),false);
          this.selectList.splice(index,1);
				}
			},
			//查询所有组织信息
			clickRefreshData(){
				this.checkTreeData={};
				this.getOrganizationTree();
				this.refreshData();
			},
			//清空已选人员
			clearSelectList(){
				if(this.isSingleSelected){
					this.$refs.multipleTable?.setCurrentRow();
				}else{
					this.$refs.multipleTable.clearSelection();
				}
				this.selectList=[];
			},
			//点击确认按键后，确认返回
			userBack(){
        this.$message.success("操作成功")
				this.$emit("userBack",this.selectList);
			},
			/*************************************数据处理事件***********************************/
			//刷新页面数据
			refreshData() {
				//刷新表格
				this.handleTableCheck()
			},
      //回显数据勾选状态
      callbackTableCheck(){
         if(this.selectList.length===0){
           return;
         }
        if(this.isSingleSelected){
          //单人回显
          this.tableData.forEach(data=>{
            if(data.id===this.selectList[0].id){
              this.$refs.multipleTable?.setCurrentRow(data);
            }
          })
        }else{
          //多人回显
          this.$nextTick(()=>{
            //回显勾选右边列表中有的数据
            this.tableData.forEach(data=>{
              const index = this.selectList.findIndex(item => item.id === data.id);
              if(index!==-1){
                this.$refs.multipleTable?.toggleRowSelection(data,true);
                const item = this.selectList[index];
                if(item.name===undefined||item.name===null){
                    this.$set(this.selectList,index,data);
                }
              }
            })
          })
        }
      },
			//处理表格查询
			handleTableCheck() {
				let param = {
					currPage: this.currPage,
					limit: this.pageSize,
					ascOrder: false,
					orderField: "",
					queryMap: {
						'organization_id': this.checkTreeData.id,
						'name': this.check.name,
						'login_name': this.check.loginName
					}
				}
				this.getUserPage(param);
			},
			/*************************************数据请求事件***********************************/
			//查询组织树
			getOrganizationTree() {
				this.$systemApi.getOrganizationTree().then(res => {
					if (res.code === 200) {
						this.organizationTree = res.data
						//存入store
						this.$store.commit('system/SET_ORGANIZATION_TREE', res.data)
					}
				})
			},
			//分页查询用户（表格）（分页）
			getUserPage(param) {
				this.$systemApi.getUserPage(param).then(res => {
					if (res.code === 200) {
						this.tableData = res.data.list;
						this.totalCount = res.data.totalCount;
						//请求成功后回显勾选
            this.callbackTableCheck();
					}
				})
			},
			/*************************************组件回调事件***********************************/

			//当每页展示条数发生变化时，重新请求,页面转回第一页(分页组件)
			handleSizeChange(pageSize) {
				this.pageSize = pageSize;
				this.handleTableCheck();
			},
			//当当前页发生变化时，重新请求(分页组件)
			handleCurrentChange(currentPage) {
				this.currPage = currentPage;
				//未选中，全文读取
				this.handleTableCheck();
			},
		}
	}
</script>

<style lang="less" scoped>
	.container {
		display: flex;
    height: calc(68.2vh);
		width: 739px;

		.left {
			flex: 1;
			border: #ECECEC solid 0.01rem;
		}

		.center {
			flex: 2;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			border-top: #ECECEC solid 0.01rem;
			border-bottom: #ECECEC solid 0.01rem;
      .table{
        overflow: auto;
      }
			.bottom{
				margin:0.1rem auto;
			}
		}

		.right {
			flex: 1;
			border: #ECECEC solid 0.01rem;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.content{
        width: 220px;
				.user-item {
					height: 0.5rem;
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin: 0.1rem 0.2rem;
					border: #ECECEC dashed 0.01rem;
					padding: 0.1rem 0.2rem;
					font-weight: bolder;
				}
			}
			.bottom{
				display: flex;
        align-items: center;
        justify-content: center;
        margin:0.1rem;
			}
		}
	}
</style>
