/**
 * 自定义代码高亮插件
 */
import hljs from 'highlight.js'
import 'highlight.js/styles/idea.css'

/**
 * 自定义权限码校验指令
 * @author PeterLee
 * @date 2022-03-01
 * @type {{inserted: highlight.inserted}}
 */
const highlight = {
    deep: true,
    bind: function (el, binding) {
        // on first bind, highlight all targets
        let targets = el.querySelectorAll('code')

        targets.forEach(target => {
            if (typeof binding.value === 'string') {
                // if a value is directly assigned to the directive, use this
                // instead of the element content.
                target.textContent = binding.value
            }
            hljs.highlightBlock(target)
        })
    },
    componentUpdated: function (el, binding) {
        // after an update, re-fill the content and then highlight
        let targets = el.querySelectorAll('code')

        targets.forEach(target => {
            if (typeof binding.value === 'string') {
                // if a value is directly assigned to the directive, use this
                // instead of the element content.
                target.textContent = binding.value
                hljs.highlightBlock(target)
            }
        })
    }
}

export default highlight
