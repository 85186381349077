<!--新建编辑表单弹出框（封装一些繁琐的表单处理逻辑）   事例：ITManage->components->*Form中有用到，可以参考 作者：PeterLee 最近修改时间：2021.9.4-->
<template>
	<el-dialog v-dialogDrag="true" :title="showTitle" :visible.sync="dialogVisible" :width="width" @close="clickDialogClose" class="base-dialog">
		<div class="container">
			<!-- 组织信息表单 -->
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px">
				<div class="info">
          <!-- 插槽出放入表单内容即可 -->
					<slot v-bind:ruleForm="ruleForm">插槽：此处放入表单内容</slot>
				</div>
				<!-- 表单操作 -->
				<el-form-item style="margin-top: 16px;margin-left: 0;display: flex;justify-content: right;">
					<el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
					<el-button @click="resetForm('ruleForm')">重置</el-button>
				</el-form-item>
			</el-form>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		name:"baseForm",
		//父组件传入参数
		props: {
			//是否增加操作（非增加操作会隐藏部分操作）
      isAdd: {
        type: Boolean,
        default: false
      },
			//唤醒弹出框
			relationDialogVisible: Boolean,
			//展示标题
			showTitle: String,
			//展示数据
			showData: Object,
			//校验规则
			rules: Object,
			//默认开始时置空(当有树存在，需要传入树id时，需为false,其他情况均需要设置为true)
			defaultEmpty:{
				type:Boolean,
				default:false
			},
      width:{
        type:String,
        // default: "36%"
        default: "750px"
      }
		},
		//计算操作
		computed: {
			
		},
		data() {
			return {
				//组件内部弹出框展示与否
				dialogVisible: false,
				//表单数据
				ruleForm: {}
			}
		},
		watch: {
			//监视父组件是否有指令需要重置页面
			relationDialogVisible: {
				handler: function(newVal, oldVal) {
					if (newVal) {
						//更新时刷新表单校验信息
						this.dialogVisible = true;
						//更新回显数据
						this.$nextTick(() => {
							if(this.defaultEmpty&&this.isAdd){
								this.ruleForm={};
								this.resetForm('ruleForm');
							}else{
								//更新回显数据,值拷贝
								this.ruleForm = JSON.parse(JSON.stringify(this.showData));
							}
						});
					}
				}
			}
		},
		methods: {
			//关闭弹窗时
			clickDialogClose() {
				this.resetForm('ruleForm');
				this.$emit("update:relationDialogVisible", false);
				this.$emit("closeDialog");
			},
			//提交表单
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						//校验通过
						if (this.isAdd) {
							//添加操作
							this.$emit("submitAdd",this.ruleForm);
						} else {
							//编辑操作
							this.$emit("submitEdit",this.ruleForm);
						}
					} else {
						//校验未通过
						this.$message.error("数据校验未通过，请核对！")
						return false;
					}
				});
			},
			//重置表单
			resetForm(formName) {
				if(this.isAdd){
					this.$refs[formName].resetFields();
				}else{
					this.ruleForm = JSON.parse(JSON.stringify(this.showData));
				}
			},
			setRuleForm(data){
				this.ruleForm=JSON.parse(JSON.stringify(data));
			},
			getRuleForm(){
				return this.ruleForm;
			},
			setVisible(visible){
				this.dialogVisible=visible;
			}
		},
		created() {

		},
		mounted() {

		},
		//生命周期updated执行时
		updated() {

		}
	}
</script>

<style lang="less" scoped>
  ::v-deep .el-dialog{
    border-radius: 6px;
  }
  ::v-deep .el-dialog__title{
    font-weight: bold;
    font-size: 0.4rem;
  }
  ::v-deep .el-dialog__close{

  }
  ::v-deep .el-dialog__body{
    padding: 20px;
  }
  ::v-deep .el-dialog--center{
      display: flex;
      .el-dialog{
         margin-top: 0;
      }
  }
  ::v-deep .el-dialog__header {
    padding: 16px 0 0;
    height: 52px;
    margin-left: 24px;
    margin-right: 24px;
    display: flex;
    align-items: center;
  }
  ::v-deep .el-dialog__headerbtn{

  }
  ::v-deep .el-dialog__headerbtn i {
    font-size: 0.4rem;
  }
 	.container {
		.title {
			font-weight: bold;
			font-size: 0.35rem;
			border-bottom: 1px solid #ccc;
			padding-bottom: 0.2rem;
			margin-bottom: 0.4rem;
		}

		.info {
			display: flex;
			flex-wrap: wrap;
      padding: 0 10px;
      max-height: calc(50vh);
      overflow: auto;
			.el-form-item {
				width: 50%;
			}
		}
	}
</style>
