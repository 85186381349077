<!-- 拥有树形控件的选项的id转特定fieldName标签（用于将tree下拉选项value转为其对应的特定字段label）参考：<script></script>中简介可供参考 作者：PeterLee 最近修改时间：2021.10.25-->
<template>
  <div>
    {{ fieldValue }}
  </div>
</template>

<script>
/**
 * @author PeterLee
 * @date 2021-10-25
 * 注：在排班管理中有使用
 */
export default {
  name: "TreeOptionNameLabel",
  //设置v-model绑定对象和自动更新响应方法
  model: {
    prop: 'id',
  },
  props: {
    id: String,
    treeData: Array,
    fieldName: {
      type:String,
      default: "name"
    },
    childrenPros:{
      type:String,
      default:"childrenList"
    }
  },
  data() {
    return {
      //字段名显示
      fieldValue: ""
    };
  },
  watch: {
    //可能存在data和id修改的情况，因为存在异步的先后顺序
    treeData: function (newVal) {
      if(this.id != 0) {
        if (newVal !== undefined && newVal !== null) {
          this.getFieldName();
        }
      }
    },
    id: function (newVal) {
      if(this.id != 0){
        if (newVal!==undefined&&newVal!==null) {
          this.getFieldName();
        }
      }
    }
  },
  methods: {
    //获取字段名
    getFieldName() {
      this.$nextTick(()=>{
        if(this.id===null || this.id === undefined){
          this.fieldValue = "";
          return;
        }
        this.fieldValue = this.findField(this.treeData, this.id, this.fieldName);
      })
    },
    findField(treeData, idValue, filedName) {
      const res=[];
      this.findValue(treeData,res)
      if (res.length>0) {
        let result = res.find((item) => item.id === idValue)[filedName];
        if (result !== undefined && result !== null) {
          return result;
        }
      }
      return idValue;
    },
    //递归查询
    findValue(data,res){
       if(data!==undefined&&data!==null&&data.length>0){
          data.forEach(item=>{
              res.push(item);
              this.findValue(item[this.childrenPros],res);
          })
       }
    }
  },
  mounted() {
    this.getFieldName();
  }
}
</script>

<style scoped>

</style>
