import { render, staticRenderFns } from "./PaginationPlus.vue?vue&type=template&id=0bf0194e&scoped=true"
import script from "./PaginationPlus.vue?vue&type=script&lang=js"
export * from "./PaginationPlus.vue?vue&type=script&lang=js"
import style0 from "./PaginationPlus.vue?vue&type=style&index=0&id=0bf0194e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bf0194e",
  null
  
)

export default component.exports