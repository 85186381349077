/**
 * 自定义element-弹出框拖拽，双击全屏，拉伸指令
 * @author PeterLee
 * @date 2021-12-10
 * @type {{bind(*, *, *, *): void}}
 */
const dialogDrag = {
    bind(el, binding, vnode, oldVnode) {
        const sizeChangeAble = binding.value
        // 自定义属性，判断是否可拖拽
        // if (!binding.value) return
        const dialogHeaderEl = el.querySelector('.el-dialog__header')
        const dragDom = el.querySelector('.el-dialog');
        //弹框可拉伸最小宽高
        let minWidth = 736;
        let minHeight = 360;
        //给弹窗加上overflow auto；不然缩小时框内的标签可能超出dialog；
        // dragDom.style.overflow = "auto";
        dialogHeaderEl.style.cssText += ';cursor:move;'
        dragDom.style.cssText += ';top:0px;'
        // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
        const sty = (function () {
            if (document.body.currentStyle) {
                // 在ie下兼容写法
                return (dom, attr) => dom.currentStyle[attr]
            } else {
                return (dom, attr) => getComputedStyle(dom, false)[attr]
            }
        })()
        let moveDown = (e) => {
            if(e.target.className === "el-dialog__close el-icon el-icon-close"){
                return
            }
            // 鼠标按下，计算当前元素距离可视区的距离
            const disX = e.clientX - dialogHeaderEl.offsetLeft
            const disY = e.clientY - dialogHeaderEl.offsetTop
            const screenWidth = document.body.clientWidth // body当前宽度
            const screenHeight = document.documentElement.clientHeight // 可见区域高度(应为body高度，可某些环境下无法获取)
            const dragDomWidth = dragDom.offsetWidth // 对话框宽度
            const dragDomheight = dragDom.offsetHeight // 对话框高度
            const minDragDomLeft = dragDom.offsetLeft
            const maxDragDomLeft = screenWidth - dragDom.offsetLeft - dragDomWidth
            const minDragDomTop = dragDom.offsetTop
            const maxDragDomTop = screenHeight - dragDom.offsetTop - dragDomheight
            // 获取到的值带px 正则匹配替换
            let styL = sty(dragDom, 'left')
            // 为兼容ie
            if (styL === 'auto') styL = '0px'
            let styT = sty(dragDom, 'top')
            // console.log(styL)
            // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
            if (styL.includes('%')) {
                styL = +document.body.clientWidth * (+styL.replace(/%/g, '') / 100)
                styT = +document.body.clientHeight * (+styT.replace(/%/g, '') / 100)
            } else {
                styL = +styL.replace(/px/g, '')
                styT = +styT.replace(/px/g, '')
            }
            document.onmousemove = function (e) {
                // 通过事件委托，计算移动的距离
                let left = e.clientX - disX
                let top = e.clientY - disY
                // 边界处理
                if (-(left) > minDragDomLeft) {
                    left = -(minDragDomLeft)
                } else if (left > maxDragDomLeft) {
                    left = maxDragDomLeft
                }
                if (-(top) > minDragDomTop) {
                    top = -(minDragDomTop)
                } else if (top > maxDragDomTop) {
                    top = maxDragDomTop
                }
                // 移动当前元素
                dragDom.style.cssText += `;left:${left + styL}px;top:${top + styT}px;`
            }
            document.onmouseup = function (e) {
                document.onmousemove = null
                document.onmouseup = null
            }
            return false
        }
        dialogHeaderEl.onmousedown = moveDown;
        //双击头部效果
        //初始非全屏
        let isFullScreen = false;
        //当前宽高
        let nowWidth = 0;
        let nowHight = 0;
        //当前顶部高度
        let nowMarginTop = 0;
        dialogHeaderEl.ondblclick = (e) => {
            if (isFullScreen === false) {
                nowHight = dragDom.clientHeight;
                nowWidth = dragDom.clientWidth;
                nowMarginTop = dragDom.style.marginTop;
                dragDom.style.left = 0;
                dragDom.style.top = 0;
                dragDom.style.height = "100VH";
                dragDom.style.width = "100VW";
                dragDom.style.marginTop = 0;
                isFullScreen = true;
                dialogHeaderEl.style.cursor = 'initial';
                dialogHeaderEl.onmousedown = null;
            } else {
                dragDom.style.height = "auto";
                dragDom.style.width = nowWidth + 'px';
                dragDom.style.marginTop = nowMarginTop;
                isFullScreen = false;
                dialogHeaderEl.style.cursor = 'move';
                dialogHeaderEl.onmousedown = moveDown;
            }
        }
        if(!sizeChangeAble){
            //配置不允许拉伸
            return;
        }
        //拉伸
        let resizeEl=document.createElement("i");
        dragDom.appendChild(resizeEl);
        //在弹窗右下角加上一个10-10px的控制块
        resizeEl.style.cursor = 'se-resize';
        resizeEl.style.position = 'absolute';
        resizeEl.style.height = '15px';
        resizeEl.style.width = '15px';
        resizeEl.style.right = '0px';
        resizeEl.style.bottom = '0px';
        // resizeEl.setAttribute('class', 'el-icon-bottom-right')
        //鼠标拉伸弹窗
        let isFirst = true;
        resizeEl.onmousedown = (e) => {
            // 记录初始x位置
            const clientX = e.clientX;
            // 鼠标按下，计算当前元素距离可视区的距离
            const disX = e.clientX - resizeEl.offsetLeft;
            const disY = e.clientY - resizeEl.offsetTop;

            document.onmousemove = function (e) {
                e.preventDefault(); // 移动时禁用默认事件

                // 通过事件委托，计算移动的距离
                const x = e.clientX - disX + (e.clientX - clientX);//这里 由于elementUI的dialog控制居中的，所以水平拉伸效果是双倍
                const y = e.clientY - disY;

                // 只执行一次，记录最小高度和宽度为原来大小，意味着只能放大，不能缩小
                if (isFirst){
                    // minWidth= x;
                    minHeight= y;
                    isFirst = false;
                }

                //比较是否小于最小宽高
                dragDom.style.width = x > minWidth ? `${x}px` : minWidth + 'px';
                dragDom.style.height = y > minHeight ? `${y}px` : minHeight + 'px';
            };
            //拉伸结束
            document.onmouseup = function (e) {
                document.onmousemove = null;
                document.onmouseup = null;
            };
        }
    }
}
export default dialogDrag