<script>
/**
 * LabelTip(标签提示form专用) 使用时要在组件上加上#label
 * @since 2022-12-09
 * @author PeterLee
 */
export default {
  name: 'LabelTip',
  componentName: 'LabelTip',
  props: {
    label: {
      type: String,
      default: ""
    },
    // 提示：可以用于解释当前字段的释义
    tip: {
      type: String,
      default: ""
    }
  }
}
</script>

<template>
  <span>
     <el-tooltip :content="tip" placement="top">
       <i class="el-icon-question"></i>
     </el-tooltip>
     {{label}}
  </span>
</template>

<style scoped>

</style>