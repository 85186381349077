import axios from '@/config/httpConfig'

// 根据用户id获取用户name
export function getUserName(id) {
    return axios.get("/system/user/name/"+id);
}
// 获取路由
export function getRouters() {
    return axios.get("/system/user/getRouters")
}

/****************** 登录注册退出等 *****************/

// 用户登录
export function login(data) {
    return axios.post('/system/user/login', data)
}

// 用户退出登录
export function logout() {
    return axios.get('/system/user/logout')
}

// 获取当前用户的信息
export function currentUserInfo() {
    return axios.get('/system/user/currentUser')
}

// 更新当前用户信息
export function updateCurrentUser(data) {
    return axios.put('/system/user/currentUser',data)
}

// 修改密码
export function changePassword(data) {
    return axios.put("/system/user/password",data)
}

// 重置密码
export function resetPassword(userId) {
    return axios.put("/system/user/password/"+userId)
}

//授权登录
export function clickAuthorizationLogin(userId) {
    return axios.put("/system/user/openAccount/"+userId)
}

// 用户请求所有权限码
export function getPermissionCodes() {
    return axios.get('/system/user/permissionCodes')
}
/* * * * * * * * * * 菜单管理相关 * * * * * * * * * * */

export function getMenuIdsByRoleId(roleId) {
    return axios.get("/system/roleToMenu/menuIds/"+roleId)
}

export function roleBatchMenu(data) {
    return axios.put("/system/roleToMenu/batchMenu",data)
}
// 模板创建菜单（添加菜单，数据平台使用）
export function createMenuTemplate(data) {
    return axios.post('/system/menu/template',data)
}
/****************** 权限管理相关 *****************/

// 修改权限存在与否(扫描菜单回填存在状态)
export function updatePermissionExist(data) {
    return axios.put('/system/menu/permission',data)
}

// 扫描菜单创建
export function scanMenuCreate(data) {
    return axios.post('/system/menu/scan',data)
}

/****************** 用户管理相关 *****************/

// 获取用户信息（分页）
export function getUserPage(data) {
    return axios.post('/system/user/page',data)
}

// 根据组织id去查询该组织的人员
// export function getUserPageByOrganizationId(data) {
//     return axios.post('/system/user/page',data)
// }

// 批量查询用户
export function getBatchUser(ids) {
    return axios.post('/system/user/infos',ids)
}

// 添加权限
export function addUser(data) {
    return axios.post("/system/user",data)
}

// 修改权限
export function updateUser(data) {
    return axios.put("/system/user",data)
}

// 删除权限
export function removeUser(data) {
    return axios.delete("/system/user",data)
}

// 单个删除权限
export function removeUserById(id) {
    return axios.delete("/system/user/"+id)
}

/****************** 组织管理相关 *****************/

// 查询组织树
export function getOrganizationTree() {
    return axios.get("/system/organization/tree")
}

// 组织分页查询
export function getOrganizationPage(data) {
    return axios.post('/system/organization/page',data)
}

// 添加组织
export function addOrganization(data) {
    return axios.post("/system/organization",data)
}

// 修改组织
export function updateOrganization(data) {
    return axios.put("/system/organization",data)
}

// 删除组织
export function removeOrganization(data) {
    return axios.delete("/system/organization",data)
}

// 单个删除组织
export function removeOrganizationById(id) {
    return axios.delete("/system/organization/"+id)
}

// 获取所有组织信息
export function getAllOrganization() {
    return axios.get("system/organization/list")
}

/****************** 角色管理相关 *****************/
// 角色分页查询
// 获取角色信息（分页）
export function getRolePage(data) {
    return axios.post('/system/role/page',data)
}

// 添加角色
export function addRole(data) {
    return axios.post("/system/role",data)
}

// 修改角色
export function updateRole(data) {
    return axios.put("/system/role",data)
}

// 更新数据范围
export function updateDataScope(data) {
    return axios.put("/system/role/dataScope",data)
}

// 删除角色
export function removeRole(data) {
    return axios.delete("/system/role",data)
}

// 单个删除角色
export function removeRoleById(id) {
    return axios.delete("/system/role/"+id)
}

// 获取所有角色（后端待完成）
export function getRoleAll() {
    return axios.get("/system/role/all")
}

// 通过一个组织下关联的角色列表(用户关联角色时使用)
export function getRoleListByOrganizationId() {
    return axios.get('/system/role/list/')
}

// 通过一个组织下关联的角色列表(用户关联角色时使用)
export function getRoleList() {
    return axios.get('/system/role/list/')
}

/****************** 职位管理相关 *****************/
// 职位分页查询
// 获取职位信息（分页）
export function getPositionPage(data) {
    return axios.post('/system/position/page',data)
}

// 添加职位
export function addPosition(data) {
    return axios.post("/system/position",data)
}

// 修改职位
export function updatePosition(data) {
    return axios.put("/system/position",data)
}

// 删除职位
export function removePosition(data) {
    return axios.delete("/system/position",data)
}

// 单个删除职位
export function removePositionById(id) {
    return axios.delete("/system/position/"+id)
}

// 获取所有职位（后端待完成）
export function getPositionAll() {
    return axios.get("/system/position/all")
}
// 通过一个组织下关联的职位列表（用户关联职位时使用）
export function getPositionListByOrganizationId(organizationId) {
    return axios.get('/system/positionList/'+organizationId)
}
/****************** 职位组织关联相关 *****************/

// 获取职位关联的组织ids(通过职位id)
export function getOrganizationIdsByPositionId(id) {
    return axios.get("/system/positionToOrganization/OrganizationIds/"+id)
}
// 一个职位批量关联组织
export function positionBatchOrganization(data) {
    return axios.put("/system/positionToOrganization/batchOrganization",data)
}

/****************** 角色组织关联相关 *****************/

// 获取角色关联的组织ids(通过角色id)
export function getOrganizationIdsByRoleId(id) {
    return axios.get("/system/roleToOrganization/OrganizationIds/"+id)
}
// 一个角色批量关联组织
export function roleBatchOrganization(data) {
    return axios.put("/system/roleToOrganization/batchOrganization",data)
}

/****************** 角色权限关联相关 *****************/

// 获取角色关联的权限ids(通过角色id)
export function getPermissionIdsByRoleId(id) {
    return axios.get("/system/roleToPermission/PermissionIds/"+id)
}
// 一个角色批量关联权限
export function roleBatchPermission(data) {
    return axios.put("/system/roleToPermission/batchPermission",data)
}

/****************** 角色用户关联相关 *****************/

// 获取用户关联的角色ids(通过角色id)
export function getRoleIdsByUserId(userId) {
    return axios.get("/system/roleToUser/"+userId+"/roleIds")
}
// 一个用户批量关联角色
export function userBatchRole(data) {
    return axios.put("/system/roleToUser/batchRole",data)
}
/****************** 字典管理 *****************/
export function dictionaryItems(code) {
    return axios.get('/system/dictionary/items/'+code)
}
