/**
 * @author PeterLee
 * @date 2021-10-21
 * vue自定义指令
 */
// 权限校验
import permission from '@/directive/permission/display'
import permissionDisabled from "@/directive/permission/disable";
// 弹出框拖拽,拉伸,双击放大
import dialogDrag from "@/directive/dialog/dialogDrag";
import highlight from "@/directive/highlight/highlight";
import drag from "@/directive/drag/drag";

const directives = {
    permission,
    permissionDisabled,
    dialogDrag,
    highlight,
    drag
}
export default {
    install(Vue) {
        Object.keys(directives).forEach((key) => {
            Vue.directive(key, directives[key])
        })
    }
}