/**
 * 菜单权限表状态管理（vuex的state可以单独出来，便于管理，同样mutations和actions都可以提取出来）
 *
 * @author PeterLee
 * @since 2022-03-10
 */
const state = {
   //表单校验规则
   rules: {
      //父菜单ID
      parentId:[{
         required: false,
         message: '请选择父菜单',
         trigger: 'change'
      }],
      //菜单类型【枚举】
      menuType:[{
         required: false,
         message: '请选择菜单类型',
         trigger: 'change'
      }],
      //菜单名称
      name:[{
         required: true,
         message: '请输入菜单名称',
         trigger: 'blur'
      },
        {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
        }
     ],
      //菜单图标
      icon:[{
         required: false,
         message: '请输入菜单图标',
         trigger: 'blur'
      },
        {
            min: 1,
            max: 100,
            message: '长度在 1 到 100 个字符',
            trigger: 'blur'
        }
     ],
      //权限标识
      perms:[{
         required: false,
         message: '请输入权限标识',
         trigger: 'blur'
      },
        {
            min: 1,
            max: 50,
            message: '长度在 1 到 50 个字符',
            trigger: 'blur'
        }
     ],
      //显示顺序
      orderNum:[{
         required: false,
         message: '请输入显示顺序',
         trigger: 'change'
      }],
      //路由地址
      path:[{
         required: false,
         message: '请输入路由地址',
         trigger: 'blur'
      },
        {
            min: 1,
            max: 200,
            message: '长度在 1 到 200 个字符',
            trigger: 'blur'
        }
     ],
      //组件路径
      component:[{
         required: false,
         message: '请输入组件路径',
         trigger: 'blur'
      },
        {
            min: 1,
            max: 200,
            message: '长度在 1 到 200 个字符',
            trigger: 'blur'
        }
     ],
      //路由参数
      query:[{
         required: false,
         message: '请输入路由参数',
         trigger: 'blur'
      },
        {
            min: 1,
            max: 255,
            message: '长度在 1 到 255 个字符',
            trigger: 'blur'
        }
     ],
      //是否为外链【枚举】
      isFrame:[{
         required: false,
         message: '请选择是否为外链',
         trigger: 'change'
      }],
      //是否缓存【枚举】
      isCache:[{
         required: false,
         message: '请选择是否缓存',
         trigger: 'change'
      }],
      //是否显示【枚举】
      visible:[{
         required: false,
         message: '请选择是否显示',
         trigger: 'change'
      }],
      //状态
      state:[{
         required: true,
         message: '请选择状态',
         trigger: 'change'
      }],
   },
   //列信息
   columnInfo: {
       //父菜单ID
       parentId:{
            visible:false,
            label:"父菜单"
       },
       //菜单类型【枚举】
       menuType:{
            visible:true,
            label:"菜单类型"
       },
       //菜单名称
       name:{
            visible:true,
            label:"菜单名称"
       },
       //菜单图标
       icon:{
            visible:true,
            label:"菜单图标"
       },
       //权限标识
       perms:{
            visible:true,
            label:"权限标识"
       },
       //显示顺序
       orderNum:{
            visible:true,
            label:"显示顺序"
       },
       //路由地址
       path:{
            visible:false,
            label:"路由地址"
       },
       //组件路径
       component:{
            visible:true,
            label:"组件路径"
       },
       //路由参数
       query:{
            visible:false,
            label:"路由参数"
       },
       //是否为外链【枚举】
       isFrame:{
            visible:false,
            label:"是否为外链"
       },
       //是否缓存【枚举】
       isCache:{
            visible:false,
            label:"是否缓存"
       },
       //是否显示【枚举】
       visible:{
            visible:false,
            label:"是否显示"
       },
       //状态
       state:{
            visible:true,
            label:"状态"
       },
   },
   //树相关
   tree: {
     //全量菜单列表
     allTableList: [],
     //表格数据（查询结果）
     tableData: [],
     //父菜单选项框
     parentList: [],
   },
   //共有数据条数（分页控件）
   totalCount: 0,
   //选项列表（列表下拉框选择）
   options: {},
   //查询参数
   queryParams: {
      //当前页数（分页控件）
      currPage: 1,
      //每页条数（分页控件）
      limit: 10,
      //是否升序
      ascOrder: false,
      //排序字段
      orderField: "",
      //此处为可能需要修改部分，请不要忽略
      param: {
         //父菜单ID
         parentId: undefined,
         //菜单类型【枚举】
         menuType: undefined,
         //菜单名称
         name: undefined,
         //菜单图标
         icon: undefined,
         //权限标识
         perms: undefined,
         //显示顺序
         orderNum: undefined,
         //路由地址
         path: undefined,
         //组件路径
         component: undefined,
         //路由参数
         query: undefined,
         //是否为外链【枚举】
         isFrame: undefined,
         //是否缓存【枚举】
         isCache: undefined,
         //是否显示【枚举】
         visible: undefined,
         //状态
         state: undefined,
      }
   },
   //时间范围
   dateRange: {

   },
   //表单组件信息
   formInfo: {
      //表单组件弹出框显示控制
      dialogVisible: false,
      //是否增加操作（非增加操作会隐藏部分操作）
      isAdd: false,
      //编辑回显数据
      editData: {},
   },
   //更多按钮信息
   moreButtonProps:[
      //{command:'test',label:'示例按钮',permissionCode:'itil:event'}
   ]
}
export default state