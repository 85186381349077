import {validateEmail, validateLoginName, validatePhone} from "@/utils/validateUtil";

/**
 * 系统用户表状态管理（vuex的state可以单独出来，便于管理，同样mutations和actions都可以提取出来）
 *
 * @author PeterLee
 * @since 2022-06-22
 */
const state = {
   //表单校验规则
   rules: {
      //用户名
      name:[{
         required: true,
         message: '请输入用户名',
         trigger: 'blur'
      },
        {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
        }
     ],
      //登录名
      loginName:[{
         validator: validateLoginName,
         required: true,
         trigger: 'blur'
      }],
      //地区代码  关联组织表
      dqCode:[{
         required: true,
         message: '请输入地区代码  关联组织表',
         trigger: 'blur'
      },
        {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
        }
     ],
      //组织id
      organizationId:[{
         required: true,
         message: '请选择组织',
         trigger: 'change'
      }],
      //职务id
      positionId:[{
         required: true,
         message: '请选择职务',
         trigger: 'change'
      }],
      //手机号码
      mobile:[{
         validator: validatePhone,
         required: true,
         trigger: 'blur'
      }],
      //邮箱
      email:[{
          validator: validateEmail,
         required: true,
         trigger: 'blur'
      }],
      //是否开通登录账号  1=是 0=否
      openAccount:[{
         required: true,
         message: '请选择是否开通登录账号',
         trigger: 'change'
      }],
      //状态 1=有效 0=无效
      state:[{
         required: true,
         message: '请选择状态',
         trigger: 'change'
      }],
   },
   //列信息
   columnInfo: {
       //用户名
       name:{
            visible:true,
            label:"用户名"
       },
       //登录名
       loginName:{
            visible: true,
            label: "登录名",
            tip: "4到16位（字母，数字，下划线，减号）"
       },
       //地区代码  关联组织表
       dqCode:{
            visible:true,
            label:"地区代码"
       },
       //组织id
       organizationId:{
            visible:true,
            label:"组织"
       },
       //职务id
       positionId:{
            visible:true,
            label:"职务"
       },
       //手机号码
       mobile:{
            visible:true,
            label:"手机号码"
       },
       //邮箱
       email:{
            visible:true,
            label:"邮箱"
       },
       //是否开通登录账号  1=是 0=否
       openAccount:{
            visible:true,
            label:"是否开通登录"
       },
       //状态 1=有效 0=无效
       state:{
            visible:true,
            label:"状态"
       },
   },
    //树数据
   tree:{
      //options
      treeData:undefined,
      //默认匹配
      defaultProps: {
         value: 'id',
         // 显示的文字字段名
         label: 'name',
         // 子层级字段名
         children: 'childrenList'
      }
   },
   //表格数据（查询结果）
   tableData: [],
   //共有数据条数（分页控件）
   totalCount: 0,
   //选项列表（列表下拉框选择）
   options: {
       //组织树（选项）
       organizationTreeOptions:undefined,
       //职位列表
       positionOptions:undefined
   },
   //查询参数
   queryParams: {
      //当前页数（分页控件）
      currPage: 1,
      //每页条数（分页控件）
      limit: 10,
      //是否升序
      ascOrder: false,
      //排序字段
      orderField: "",
      //此处为可能需要修改部分，请不要忽略
      param: {
         //用户名
         name: undefined,
         //登录名
         loginName: undefined,
         //地区代码  关联组织表
         dqCode: undefined,
         //组织id
         organizationId: undefined,
         //职务id
         positionId: undefined,
         //手机号码
         mobile: undefined,
         //邮箱
         email: undefined,
         //是否开通登录账号  1=是 0=否
         openAccount: undefined,
         //状态 1=有效 0=无效
         state: undefined,
      }
   },
   //时间范围
   dateRange: {

   },
   //表单组件信息
   formInfo: {
      //表单组件弹出框显示控制
      dialogVisible: false,
      //是否增加操作（非增加操作会隐藏部分操作）
      isAdd: false,
      //编辑回显数据
      editData: {},
   },
   //更多按钮信息
   moreButtonProps:[
      //{command:'test',label:'示例按钮',permissionCode:'itil:event'}
   ]
}
export default state