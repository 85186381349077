<script>
/**
 * 图片上传选择器
 * @author PeterLee
 * @since 2023-02-16
 */
import {getFileUploadSign, imgDownload} from "@/api/baseApi";

export default {
  name: "ImageUploadSelect",
  //设置v-model绑定对象和自动更新响应方法
  model: {
    prop: 'fileList',
    event: 'change',
  },
  props: {
    // 文件对象list
    fileList: {
      type: Array,
      default: [],//每个单元为{path:'',url:''}
    },
    // 图片张数限制，默认8张
    limit: {
      type: Number,
      default: 8
    },
    // 禁用图片的下载和删除功能（只能预览）
    disabled: {
      type: Boolean,
      default: false
    },
    // 允许相同的文件
    allowTheSame: {
      type: Boolean,
      default: true
    },
    // 文件限制类型（可以自行拓展）
    limitType: {
      type: Array,
      default: () => ['jpg', 'png', 'webp', 'gif']
    },
    // 文件限制大小
    limitSize: {
      type: String,
      default: "1M"
    }
  },
  data() {
    return {
      // 初始图片备份
      initialImages: [],
      // upload组件内部fileList
      images: [],
      // 控制上传组件弹出框显示
      dialogVisible: false,
      // 控制图片预览弹出框显示
      previewDialogVisible: false,
      // 上传图片后预览地址容器
      dialogImageUrl: '',
      // 是否显示滚动条，通过该变量自动拓宽弹出框
      hasScroll: false,
      // 弹出框下部提示追加异常提示文字
      tip: '',
      // 文件上传路径，上传到文件服务器的路径（由签名自动获取填写）
      uploadUrl: '',
      // 控制提示抖动
      tipShake: false,
      // 文件上传分组名
      groupName: ''
    }
  },
  computed: {
    tagValue: {
      get() {
        return this.fileList.map(item => {
          return item.name
        });
      },
      set(val) {
        // this.$emit('change', val)
      }
    },
  },
  mounted() {
    console.log("[Info]the widget of image-upload-select mounted...")
    getFileUploadSign().then(res => {
      if (res.code === 200) {
        this.uploadUrl = res.data.uploadUrl + "?code=" + res.data.code
        this.groupName = res.data.groupName
        // 动态正则,取出后面的
        let reg = new RegExp('((\\S*))' + this.groupName)
        let path = reg.exec(res.data.uploadUrl)[1]
        this.fileList.forEach(item => {
          let url = path + item.url
          // 不要让内部参数与外部参数有引用绑定，引用绑定无法形成隔离
          this.images.push({name: item.name, url: url})
        })
      }
    })
  },
  methods: {
    // 图片上传弹出框关闭触发事件
    clickDialogClose() {
      // 向上抛出回调接口
      this.$emit('clickDialogClose')
      // 不做任何其他操作
    },
    // tag输入框聚焦事件
    selectFocus() {
      this.openDialog()
    },
    // tag输入框失去焦点事件
    selectBlur() {

    },
    // 标签输入框点击标签’x‘的回调
    tagClose() {
      this.openDialog()
    },
    // 点击删除
    handleRemove(file) {
      console.log("[Info]the file removing...")
      this.$confirm(`确定移除 ${file.name}？`).then(() => {
        console.log("[Info]the file removing success...")
        this.images.splice(this.images.findIndex(item => item.uid === file.uid), 1);
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        console.log("[Info]the file removing cancel...")
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 点击预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.previewDialogVisible = true
    },
    // 点击下载
    handleDownload(file) {
      console.log("[Info]the file downloading...")
      let path = file['url']
      imgDownload(path + "?download=1", file.name);
    },
    // 超限事件
    onExceed() {
      this.changeTip('已达上限,不能继续上传')
    },
    // 改变监听
    onChange(file, fileList) {
      this.hasScroll = fileList.length > 8;
    },
    // 上传成功
    onSuccess(response, file, fileList) {
      console.log("[Info]the file upload success...")
      file.url = response
      if (!this.allowTheSame && this.images.findIndex(item => item.url === response) > -1) {
        fileList.pop()
        this.changeTip('此处不能重复上传相同图片')
        return
      }
      this.images.push(file)
    },
    // 点击重置
    resetClick() {
      // 没有备份的时候不进行重置
      if (this.initialImages.length > 0) {
        // 不要让内部参数与外部参数有引用绑定，引用绑定无法形成隔离
        this.images = JSON.parse(JSON.stringify(this.initialImages))
      }
      console.log("[Info]the file reset success...")
    },
    // 确认按钮点击
    submitClick() {
      console.log("[Info]the widget of image-upload-select select confirm...")
      // 将上传的图片信息绑定到v-model中（向上数据），将图片信息显示到tagInput中（向下显示）
      let fileList = []
      // 动态正则,取出后面的
      let reg = new RegExp(this.groupName + '(\\S*)')
      this.images.forEach(item => {
        let path = "/" + reg.exec(item.url)[0]
        fileList.push({name: item.name, url: path})
      })
      // 上传成功之后备份
      this.initialImages = JSON.parse(JSON.stringify(this.images))
      this.$emit('change', fileList)
      this.dialogVisible = false
    },
    // 上传之前
    beforeUpload(file) {
      // 第一次上传之前备份（避免首次点击重置时发生数值抖动）
      if (this.initialImages.length === 0) {
        this.initialImages = JSON.parse(JSON.stringify(this.images))
      }
      // 校验上传文件的类型
      console.log("[Info]file in the process of type matching...")
      // 获取文件后缀名
      const fileType = file.name.substring(file.name.lastIndexOf('.') + 1)
      if (!this.limitType.includes(fileType)) {
        this.changeTip('此处不能上传' + fileType + '文件')
        console.log("[Wrong]file limit type unit error...")
        return false;
      }
      let unit = this.limitSize.replace(/[0-9]*/g, '').replace(/\./, '')
      if (unit !== "M" && unit !== "K") {
        console.log("[Wrong]file limit size unit error...")
        return false;
      }
      let reg = new RegExp('(\\S*)' + unit)
      let num = Number(reg.exec(this.limitSize)[1])
      if (unit === 'M' && file.size > num * 1024 * 1024) {
        console.log("[Wrong]file limit size is out...")
        this.changeTip('图片大小不能超过' + this.limitSize)
        return false;
      }
      if (unit === 'K' && file.size > num * 1024) {
        console.log("[Wrong]file limit size is out...")
        this.changeTip('文件大小不能超过' + this.limitSize)
        return false;
      }
      console.log("[OK]file all limit is OK...")
      return true;
    },
    // 图片上传组件框显示（获取签名）
    openDialog() {
      this.dialogVisible = true
    },
    // 改变提示
    changeTip(tip) {
      this.tip = '(' + tip + ')'
      if (tip !== '') {
        this.tipShake = true
        setTimeout(() => {
          this.tipShake = false;
          this.tip = ''
        }, 1820); // 动画持续时间为0.82s
      }
    }
  }
}
</script>

<template>
  <div>
    <el-tag-input v-model="tagValue" tag-type="success" @tagClose="tagClose" @focus="selectFocus" :remove-able="false"
                  @blur="selectBlur"/>
    <el-dialog v-dialogDrag title="图片上传" :visible.sync="dialogVisible" @close="clickDialogClose" center
               :width="hasScroll?'540px':'520px'" append-to-body>
      <div class="container">
        <el-upload
            :action="uploadUrl"
            :limit="limit"
            :file-list="images"
            list-type="picture-card"
            :auto-upload="true"
            :before-upload="beforeUpload"
            style="overflow: auto;"
            :on-exceed="onExceed"
            :on-success="onSuccess"
            :on-change="onChange">
          <i slot="default" class="el-icon-plus"></i>
          <div slot="file" slot-scope="{file}">
            <img
                class="el-upload-list__item-thumbnail"
                :src="file.url" alt=""
            >
            <span class="el-upload-list__item-actions">
              <span
                  class="el-upload-list__item-preview"
                  @click="handlePictureCardPreview(file)"
              >
                  <i class="el-icon-zoom-in"></i>
              </span>
              <span
                  v-if="!disabled"
                  class="el-upload-list__item-delete"
                  @click="handleDownload(file)"
              >
                  <i class="el-icon-download"></i>
              </span>
              <span
                  v-if="!disabled"
                  class="el-upload-list__item-delete"
                  @click="handleRemove(file)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </el-upload>
        <el-dialog :visible.sync="previewDialogVisible" width="650px" top="calc(10vh)" append-to-body>
          <img style="height: calc(65vh);" width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
        <div>
          <div style="display: flex;align-items: center;">
            <div class="upload__tip">只能上传jpg/png等图片文件,且不超过{{ limit }}张</div>
            <div style="color: red;" :class="{ 'tip-shake': tipShake }">&nbsp{{ tip }}</div>
          </div>
          <div style="display: flex;align-items: flex-end;flex-direction: column;">
            <div style="display: flex;flex-direction: row;">
              <el-button @click="resetClick">重置</el-button>
              <el-button type="success" @click="submitClick">确定</el-button>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<style scoped lang="less">
::v-deep .el-dialog {
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}

::v-deep .el-dialog__title {
  font-weight: bold;
  font-size: 0.4rem;
}

::v-deep .el-dialog__close {

}

::v-deep .el-dialog__body {
  padding: 5px 24px 24px;
}

::v-deep .el-dialog--center {
  display: flex;

  .el-dialog {
    margin-top: 0;
  }
}

::v-deep .el-dialog__header {
  padding: 16px 0 0;
  height: 52px;
  margin-left: 24px;
  margin-right: 24px;
  display: flex;
  align-items: center;
}

::v-deep .el-dialog__headerbtn {

}

::v-deep .el-dialog__headerbtn i {
  font-size: 0.4rem;
}

.container {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: calc(54.2vh);
  justify-content: space-between;
  width: 100%;

  .upload__tip {
    margin: 10px 0 12px;
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.tip-shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}
</style>