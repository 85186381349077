/**
 * 数据库表状态管理（vuex的state可以单独出来，便于管理，同样mutations和actions都可以提取出来）
 *
 * @author PeterLee
 * @since 2022-07-18
 */
const state = {
   // 表单校验规则
   rules: {
     //  // 数据库名
     //  name: [{
     //     required: true,
     //     message: '请输入数据库名',
     //     trigger: 'blur'
     //  },
     //    {
     //        min: 1,
     //        max: 20,
     //        message: '长度在 1 到 20 个字符',
     //        trigger: 'blur'
     //    }
     // ],
     //  // 数据源id
     //  dataSourceId: [{
     //     required: true,
     //     message: '请输入数据源id',
     //     trigger: 'blur'
     //  },
     //    {
     //        min: 1,
     //        max: 20,
     //        message: '长度在 1 到 20 个字符',
     //        trigger: 'blur'
     //    }
     // ],
     //  // 备注
     //  comment: [{
     //     required: true,
     //     message: '请输入备注',
     //     trigger: 'blur'
     //  },
     //    {
     //        min: 1,
     //        max: 20,
     //        message: '长度在 1 到 20 个字符',
     //        trigger: 'blur'
     //    }
     // ],
      // 模块名
      moduleName: [{
         required: false,
         message: '请输入模块名',
         trigger: 'blur'
      },
        {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
        }
     ],
      // 作者名字
      authorName:[{
         required: false,
         message: '请输入作者名字',
         trigger: 'blur'
      },
        {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
        }
     ],
      // 模块描述
      moduleDescription: [{
         required: false,
         message: '请输入模块描述',
         trigger: 'blur'
      },
        {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
        }
     ],
      // 包名
      packageName: [{
         required: false,
         message: '请输入包名',
         trigger: 'blur'
      },
        {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
        }
     ],
      // 表前缀
      prefix: [{
         required: false,
         message: '请输入表前缀（为空即不去掉前缀）',
         trigger: 'blur'
      },
        {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
        }
     ],
      // 后端路径
      javaPath:[{
         required: false,
         message: '请输入后端路径',
         trigger: 'blur'
      },
        {
            min: 1,
            max: 255,
            message: '长度在 1 到 255 个字符',
            trigger: 'blur'
        }
     ],
      // 前端路径
      vuePath:[{
         required: false,
         message: '请输入前端路径',
         trigger: 'blur'
      },
        {
            min: 1,
            max: 255,
            message: '长度在 1 到 255 个字符',
            trigger: 'blur'
        }
     ],
      // 状态
      state: [{
         required: true,
         message: '请输入状态',
         trigger: 'change'
      }
     ],
   },
   // 列信息
   columnInfo: {
       // 数据库名
       name: {
            visible: true,
            label: "数据库名"
       },
       // 数据源id
       dataSourceId: {
            visible: true,
            label: "数据源"
       },
       // 备注
       comment: {
            visible: true,
            label: "备注"
       },
       // 模块名
       moduleName: {
            visible: true,
            label: "模块code",
            tip: "模块名：后端接口路径的构成关键，也是前端菜单路由的组成部分，请用简短的英文单词来定义,如'system'"
       },
       // 作者名字
       authorName: {
            visible: true,
            label: "作者名字",
            tip: "作者名字：是用于代码生成的注释中使用的，用于标记代码文档的创作者，建议与git中的提交名称一致"
       },
       // 模块描述
       moduleDescription: {
            visible: true,
            label: "模块名",
            tip: "模块名：此处建议用中文字符，如'系统设置'，在自动创建菜单时，与菜单名称对应，也会出现在代码注释中，请谨慎填写"
       },
       // 包名
       packageName: {
            visible: true,
            label: "包名",
            tip: "包名:即是生成代码的包路径,主要用于后端.."
       },
       // 表前缀
       prefix: {
            visible: true,
            label: "表前缀",
            tip: "表前缀：（如：sys_example,则此处填写‘sys’即可），目的是为了代码生成的时候去掉前缀，没有或不计划去掉，可以不填此项"
       },
       // 后端路径
       javaPath: {
            visible: true,
            label: "后端路径",
            tip: "后端路径要填写到包名这一级，如：../com/yida/"
       },
       // 前端路径
       vuePath: {
            visible: true,
            label: "前端路径",
            tip: "前端路径是要填写到模块名这一级，如：../views/system/"
       },
       // 状态
       state: {
            visible: true,
            label: "状态"
       },
   },
   // 表格数据（查询结果）
   tableData: [],
   // 共有数据条数（分页控件）
   totalCount: 0,
   // 选项列表（列表下拉框选择）
   options: {
       sourceOptions: []
   },
   // 查询参数
   queryParams: {
      // 当前页数（分页控件）
      currPage: 1,
      // 每页条数（分页控件）
      limit: 10,
      // 是否升序
      ascOrder: false,
      // 排序字段
      orderField: "",
      // 此处为可能需要修改部分，请不要忽略
      param: {
         // 数据库名
         name: undefined,
         // 数据源id
         dataSourceId: undefined,
         // 备注
         comment: undefined,
         // 模块名
         moduleName: undefined,
         // 作者名字
         authorName: undefined,
         // 模块描述
         moduleDescription: undefined,
         // 包名
         packageName: undefined,
         // 表前缀（为空即不去掉前缀）
         prefix: undefined,
         // 后端路径
         javaPath: undefined,
         // 前端路径
         vuePath: undefined,
         // 状态
         state: undefined,
      }
   },
   // 时间范围
   dateRange: {

   },
   // 表单组件信息
   formInfo: {
      // 表单组件弹出框显示控制
      dialogVisible: false,
      // 是否增加操作（非增加操作会隐藏部分操作）
      isAdd: false,
      // 编辑回显数据
      editData: {},
   },
   // 更多按钮信息
   moreButtonProps: [
      // {command:'test',label:'示例按钮',permissionCode:'itil:event'}
   ]
}
export default state