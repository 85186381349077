<!--底部批量删除、禁用、启用组件 事例：UserManage有用到 作者：PeterLee 最近修改时间：2021.9.7-->
<template>
  <div class="pagi-container">
    <div>
      <el-button type="danger" size="small" plain @click="handleDelAll" :disabled="delDisabled" v-show="delShow" v-permissionDisabled="delAllPermissionCode">批量删除</el-button>
      <el-button type="primary" size="small" plain @click="handleState(false)" :disabled="stateDisabled" v-show="stateShow" v-permissionDisabled="statePermissionCode">启用</el-button>
      <el-button type="warning" size="small" plain @click="handleState(true)" :disabled="stateDisabled" v-show="stateShow" v-permissionDisabled="statePermissionCode">禁用</el-button>
    </div>
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                   :current-page="param.currPage" :page-sizes="pageSizes" :page-size="param.limit"
                   layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
    </el-pagination>
  </div>
</template>

<script>
/**
 * 页面底部控件升级版（包含 批量删除，启用禁用，分页组件等）
 * @author PeterLee
 * @since 2021-09-07
 */
import {removeData, updateState} from "@/api/baseApi";

export default {
  //父组件传入参数
  props: {
    //批量删除权限码
    delAllPermissionCode: {
      type: String,
      default: '*:*:*'
    },
    //状态按钮权限码
    statePermissionCode:{
      type: String,
      default: '*:*:*'
    },
    //每页显示多少条选项数组
    pageSizes: {
      type: Array,
      default: [10, 20, 40, 80]
    },
    param:{
      type: Object,
      default: {
         currPage: 1,
         limit: 10
      }
    },
    //初始值一共多少条数据
    totalCount: {
      type: Number,
      default: 0
    },
    //批量操作选中list
    selectList: {
      type: Array,
      default: []
    },
    //模块名
    moduleName:String,
    //表名
    tableName:String,
    // 删除disabled
    delDisabled:{
      type: Boolean,
      default: false
    },
    delShow:{
      type: Boolean,
      default: true
    },
    // 状态disabled
    stateDisabled:{
      type: Boolean,
      default: false
    },
    stateShow:{
      type: Boolean,
      default: true
    }
  },
  data() {
    return {

    }
  },
  methods: {
    //当每页展示条数发生变化时，重新请求,页面转回第一页
    handleSizeChange(pageSize) {
      //传回每页条数
      this.param.limit = pageSize;
      //刷新表格数据
      this.$emit('handleTableRefresh');
    },
    //当当前页发生变化时，重新请求
    handleCurrentChange(currentPage) {
      //传回页码
      this.param.currPage = currentPage;
      //刷新表格数据
      this.$emit('handleTableRefresh');
    },
    //点击批量删除时
    handleDelAll() {
      let array = this.getSelectList();
      if(array != null){
        //弹出确认框
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          removeData(this.moduleName, this.tableName, array).then(res => {
            if (res.code === 200) {
              //刷新表格数据
              this.$emit('handleTableRefresh');
              //顶部显示提示
              this.$message({
                type: 'success',
                message: '删除成功'
              });
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }
    },
    //点击启用禁用时,disabled==true为禁用
    handleState(disabled) {
      let array = this.getSelectList();
      if(array!=null){
        //弹出确认框
        this.$confirm('此操作将改变所选数据状态, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //此处0为禁用，1为启用（根据自己代码情况进行调整）
          const state = disabled ? 0 : 1;
          let param = {
            ids: array,
            state: state
          }
          //更新状态
          updateState(this.moduleName, this.tableName, param).then(res => {
            if (res.code === 200) {
              //刷新表格数据
              this.$emit('handleTableRefresh');
              //顶部显示提示
              this.$message({
                type: 'success',
                message: '状态修改成功！'
              });
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });
        });
      }
    },
    //获取选中的数据
    getSelectList() {
      let array = [];
      this.selectList.forEach(row => {
        array.push(row.id);
      })
      if (array.length === 0) {
        this.$message({
          type: 'info',
          message: '请选择要操作的数据'
        });
        return null;
      }
      return array;
    },
  }
}
</script>

<style lang="less" scoped>
.pagi-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
