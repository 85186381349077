<!-- 单用户人员输入框 作者：PeterLee 最新更新时间：2021-9-15 使用范例见：TrainingPlanForm.vue文件 -->
<template>
  <div>
    <el-input :value="userName" @focus="userFocus" :disabled="disabled" :size="size" :placeholder="placeholder"></el-input>
    <user-selector :data="ids" :selectorVisible.sync="selectorVisible" :isSingleSelected="true" @userBack="userBack" />
  </div>
</template>

<script>
/**
 * 注：1.使用时可以直接使用v-model双向绑定props里面的id值，即为用户id；
 *    2.name属性需要加上.sync方便组件内部更新
 *    3.上述原则见vue官网自定义组件章节，或参考：https://www.cnblogs.com/lhuser/p/11269546.html
 *    4.要考虑回显问题，即编辑时需要通过用户id回显用户名
 */
import UserSelector from "@/components/widget/UserSelector"
export default {
  name: "SingleUserInput",
  //设置v-model绑定对象和自动更新响应方法
  model:{
    prop:'id',
    event:'change'
  },
  props: {
    id: String,
    name:String,
    disabled:{
      type:Boolean,
      default:false
    },
    size:{
      type:String,
      default: "middle"
    },
    placeholder:{
      type:String,
      default: "请选择人员"
    }
  },
  components: {
    UserSelector
  },
  watch: {
    id:{
      handler(val){
        //没传入name
        if (val&&!this.name) {
          this.$nextTick(() => {
            this.$store.dispatch('system/getUserName', this.id).then(res => {
              this.userName=res;
            })
          })
        }
        //传入了name
        if(val&&this.name){
           this.userName=this.name;
        }else{
           //id变化了，id为空，则name为空
           this.userName="";
        }
      }
    }
  },
  data() {
    return {
      //选人控件显示
      selectorVisible:false,
      //用户名
      userName:"",
      //传入的用户ids
      ids:[],
    };
  },
  methods: {
    //培训人聚焦
    userFocus() {
      this.selectorVisible = true;
      //下面不是废代码，而是重新改变值，否则子组件初次回显会为空
      this.ids=[];
      if(this.id!==undefined&&this.id!==null&&this.id.length!==0){
        this.ids.push(this.id);
      }
    },
    //用户选择器返回用户
    userBack(data) {
      this.selectorVisible=false;
      if(data.length!==0){
        this.$emit("update:name",data[0].name);
        this.$emit("change",data[0].id);
      }else{
        this.$emit("update:name","");
        this.$emit("change","");
      }
    }
  },
}
</script>

<style scoped>

</style>