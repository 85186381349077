/**
 * 数据源表状态管理（vuex的state可以单独出来，便于管理，同样mutations和actions都可以提取出来）
 *
 * @author PeterLee
 * @since 2022-07-15
 */
const state = {
    // 表单校验规则
    rules: {
        // 名称
        name: [{
            required: true,
            message: '请输入名称',
            trigger: 'blur'
        },
            {
                min: 1,
                max: 20,
                message: '长度在 1 到 20 个字符',
                trigger: 'blur'
            }
        ],
        // 资源路径
        url: [{
            required: true,
            message: '请输入资源路径',
            trigger: 'blur'
        },
            {
                min: 1,
                max: 120,
                message: '长度在 1 到 120 个字符',
                trigger: 'blur'
            }
        ],
        // 账号
        account: [{
            required: true,
            message: '请输入账号',
            trigger: 'blur'
        },
            {
                min: 1,
                max: 20,
                message: '长度在 1 到 20 个字符',
                trigger: 'blur'
            }
        ],
        // 密码
        password: [{
            required: true,
            message: '请输入密码',
            trigger: 'blur'
        },
            {
                min: 1,
                max: 20,
                message: '长度在 1 到 20 个字符',
                trigger: 'blur'
            }
        ],
        // 类型
        typeId: [{
            required: true,
            message: '请输入类型',
            trigger: 'change'
        }],
        // 序号
        sort: [{
            required: true,
            message: '请输入序号',
            trigger: 'blur'
        },
            {
                min: 1,
                max: 20,
                message: '长度在 1 到 20 个字符',
                trigger: 'blur'
            }
        ],
        // 状态
        state: [{
            required: true,
            message: '请输入状态',
            trigger: 'change'
        }],
    },
    // 列信息
    columnInfo: {
        // 名称
        name: {
            visible: true,
            label: "名称"
        },
        // 资源路径
        url: {
            visible: true,
            label: "资源路径"
        },
        // 账号
        account: {
            visible: true,
            label: "账号"
        },
        // 密码
        password: {
            visible: true,
            label: "密码"
        },
        // 类型
        typeId: {
            visible: true,
            label: "类型"
        },
        // 序号
        sort: {
            visible: true,
            label: "序号"
        },
        // 状态
        state: {
            visible: true,
            label: "状态"
        },
    },
    // 表格数据（查询结果）
    tableData: [],
    // 共有数据条数（分页控件）
    totalCount: 0,
    // 选项列表（列表下拉框选择）
    options: {
        typeOptions: [],
    },
    // 查询参数
    queryParams: {
        // 当前页数（分页控件）
        currPage: 1,
        // 每页条数（分页控件）
        limit: 10,
        // 是否升序
        ascOrder: false,
        // 排序字段
        orderField: "",
        // 此处为可能需要修改部分，请不要忽略
        param: {
            // 名称
            name: undefined,
            // 资源路径
            url: undefined,
            // 账号
            account: undefined,
            // 密码
            password: undefined,
            // 类型
            typeId: undefined,
            // 序号
            sort: undefined,
            // 状态
            state: undefined,
        }
    },
    // 时间范围
    dateRange: {},
    // 表单组件信息
    formInfo: {
        // 表单组件弹出框显示控制
        dialogVisible: false,
        // 是否增加操作（非增加操作会隐藏部分操作）
        isAdd: false,
        // 编辑回显数据
        editData: {},
    },
    // 更多按钮信息
    moreButtonProps: [
        //{command:'test',label:'示例按钮',permissionCode:'itil:event'}
    ]
}
export default state