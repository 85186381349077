/**
 * created by wbz20
 * on 2019/2/12
 * */

export default {
    // 字体大小默认14
    fontSize: 14,
    // 计算字体比率
    fontRate: {
        CHAR_RATE: 1.1, // 汉字比率
        NUM_RATE: 0.65, // 数字
        OTHER_RATE: 0.5 // 除汉字和数字以外的字符的比率
    },
}
