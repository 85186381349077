// 校验非中文
export function validateUnChinese(rules,value,callback) {
    if (value) {
        if (/[^\u4E00-\u9FA5]/g.test(value)) {
            callback()
        } else {
            callback(new Error('不能包含中文'))
        }
    }
    callback()
}
// 校验数字
export function validateNum(rules,value,callback) {
    if (value) {
        if (/^[0-9]\d*$/g.test(value)) {
            callback()
        } else {
            callback(new Error('请输入数字'))
        }
    }
    callback()
}
// 校验数字，保留两位小数
export function validateNumWithTwo(rules,value,callback) {
    if (value) {
        if (/^[0-9]+(.[0-9]{1,2})?$/g.test(value)) {
            callback()
        } else {
            callback(new Error('请输入数字（两位小数）'))
        }
    }
    callback()
}
// IPV4校验
export function validateIPV4(rules,value,callback) {
    if (value) {
        if (/^((25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))\.){3}(25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d))|\*)((\/([012]\d|3[012]|\d))?)(,((25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))\.){3}(25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d))|\*)((\/([012]\d|3[012]|\d))?))*$/g.test(value)) {
            callback()
        } else {
            callback(new Error('请输入合法ipv4地址'))
        }
    }
    callback()
}
// IPV6校验
export function validateIPV6(rules,value,callback) {
    if (value) {
        let ipv6 = /^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$/g
        if (ipv6.test(value)) {
            callback()
        } else {
            callback(new Error('请输入合法ipv6地址'))
        }
    }
    callback()
}

// 端口限制
export function validateWebPort(rules,value,callback) {
    if (value) {
        if (/^[0-9]\d*$/g.test(value)) {
            if (/^([0-9]|[1-9]\d|[1-9]\d{2}|[1-9]\d{3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])$/g.test(value)) {
                callback()
            } else {
                callback(new Error('端口数值限制为0-65535'))
            }
        } else {
            callback(new Error('请输入数字'))
        }
    }
    callback()
}
// 金额不能超过十位数
export function validateMoney(rules,value,callback) {
    if (value) {
        if (/^[0-9]\d*$/g.test(value)) {
            if ( /^(([0-9]|([1-9][0-9]{0,9}))((\.[0-9]{1,2})?))$/g.test(value)) {
                callback()
            } else {
                callback(new Error('金额最大不能超过10位'))
            }
        } else {
                callback(new Error('请输入数字'))
            }
    }
    callback()
}
// 电话格式限定
export function validatePhone(rules,value,callback) {
    if (value) {
        if (/^[0-9]\d*$/g.test(value)) {
            if (  /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/g.test(value)) {
                callback()
            } else {
                callback(new Error('请输入正确的电话号码'))
            }
        } else {
            callback(new Error('请输入正确的电话号码'))
        }
    }
    if (rules.required) {
        callback(new Error('请输入电话号码'))
    }
    callback()
}
// 校验正数数
export function validateNumInteger(rules,value,callback) {
    if (value) {
        if (/^[0-9]+$/g.test(value)) {
            callback()
        } else {
            callback(new Error('请输入正数数'))
        }
    }
    callback()
}
// 校验字母和数字  只能匹配字母和数字
export function validateLetterAndNumber(rules,value,callback) {
    if (value) {
        if (/^[a-z-A-z0-9]+$/g.test(value)) {
            callback()
        } else {
            callback(new Error('不能包含中文'))
        }
    }
    callback()
}

// 校验邮箱地址
export const validateEmail = (rules, value, callback) => {
    if (rules.required && value==='' || value ===undefined) {
        // 如果邮箱为空，则认为是无效的
        callback(new Error('请输入邮箱地址'));
    }
    if (/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)*\.(com|net|org)$/g.test(value)) {
        // 如果邮箱格式正确，则通过验证
        callback();
    } else {
        // 如果邮箱格式不正确，则返回错误信息
        callback(new Error('请输入合法邮箱地址'));
    }
};

// 校验登录名，4到16位（字母，数字，下划线，减号）
export function validateLoginName(rules,value,callback) {
    if (value) {
        if (/^[a-zA-Z0-9_-]{4,16}$/g.test(value)) {
            callback()
        } else {
            callback(new Error('您的登录名不符合要求'))
        }
    }
    callback(new Error("请输入登录名"))
}